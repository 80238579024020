import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import axios from 'axios';
import numberFormatter from 'utils/formatNumber';
import { API_BASE_ADDRESS } from 'pages/audienceManager/variables';
import {
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
} from '@klover/attain-design-system';
import {
  Dimension,
  DimensionCategoryCondition,
  DimensionOperator,
  DimensionOperators,
  ToggleDimensionDialogAction,
  applyDimension,
  currentCounts,
  populateCurrentAnswers,
  query,
  querySurveys,
  queryTotalAudienceSize,
  resetCurrentAnswers,
  resetCurrentConditions,
  toggleDimensionDialog,
  updateCurrentAnswers,
  updateCurrentDimension,
  updateCurrentResponses,
  updateCurrentSurvey,
  updateDimension,
  updatingTotalAudience,
} from '../slice';
import { nanoid } from 'nanoid';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import * as Styled from './index.styles';

// Material UI Components
import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@mui/material/ListSubheader';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

// Page Components
import DimensionConditions from '../dimensionConditions';
import DimensionIcon from '../dimensionIcon';
import TransactionFilters from '../transactionFilters';
import { MULTIPLIER } from 'pages/variables';

import Auto from 'components/Autocomplete';
import SpinnerAudienceIcon from 'assets/images/supervisor-account-dialog.svg';
import TextFieldSearch from 'components/TextFieldSearch/TextFieldSearch';
import tokens from 'tokens';
import { CaretDown, Users, UsersThree } from '@phosphor-icons/react';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      marginLeft: 127,
      height: 22,
      marginBottom: 5,
    },
    column: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: 107,
      height: 22,
      marginBottom: 5,
    },
    tags: {
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: 16,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
    header: {
      padding: '10px 12px 10px 24px',
    },
    operatorControl: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      minWidth: 50,
    },
    divider: {
      borderLeft: '3px solid ' + tokens.colorDividerBorder,
      paddingLeft: 5,
      flexGrow: 1,
    },
    close: {
      height: 24,
      width: 24,
    },
    formControl: {
      minWidth: 100,
      maxWidth: 350,
    },
    formControlOverflow: {
      minWidth: 100,
      maxWidth: 350,
      whiteSpace: 'normal',
    },
    spinner: {
      marginTop: 9,
      color: tokens.colorSpinnerCircular,
    },
    conditionSpinner: {
      display: 'flex',

      '& > * + *': {
        marginLeft: 10,
      },
    },
    listSubheader: {
      marginTop: 16,
    },
    surveyDialogRow: {
      paddingLeft: 16,
      justifyContent: 'space-around',
      flexWrap: 'nowrap',
      display: 'flex',
      columnGap: '48px',
    },
    questionSelectorMenuItem: {
      backgroundColor: 'white',
      maxWidth: '500px',
      whiteSpace: 'normal',
    },
    surveyAnswerListItem: {
      justifyContent: 'space-between',
    },
    surveySpinner: {
      minWidth: '100px',
      textAlign: 'right',
    },
    answerText: {
      paddingRight: '8px',
      fontFamily: 'Calibre-Regular, sans-serif',
      fontSize: 16,
    },
    headerMainText: {
      fontFamily: 'Calibre-bold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
    },
    headerSubText: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontSize: 18,
      padding: '2px 0px 0px 10px',
    },
  })
);

const dialog = createTheme({
  overrides: {
    MuiDialog: {
      paper: {
        borderRadius: 10,
      },
      paperWidthMd: {
        maxWidth: 1100,
      },
    },
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiInputBase: {
      input: {
        borderRadius: 10,
        padding: '12px 14px',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiButton: {
      root: {
        borderRadius: 8,
      },
      outlinedPrimary: {
        color: tokens.colorButtonPrimary,
      },
      containedPrimary: {
        backgroundColor: tokens.colorButtonPrimary,
        '&:hover': {
          backgroundColor: tokens.colorButtonPrimary,
        },
      },
    },
  },
});

const icon = createTheme({
  overrides: {
    MuiSvgIcon: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
});

const spinner = createTheme({
  overrides: {
    MuiCircularProgress: {
      root: {
        maxHeight: 20,
        maxWidth: 20,
      },
      colorPrimary: {
        color: tokens.colorSpinnerDefault,
      },
    },
  },
});

const select = createTheme({
  overrides: {
    MuiSelect: {
      selectMenu: {
        whiteSpace: 'normal',
      },
    },
  },
});

const checkbox = createTheme({
  overrides: {
    MuiCheckbox: {
      colorPrimary: {
        color: tokens.colorTextDark,
      },
    },
  },
});

interface Props {
  open: boolean;
  isUnchanged: boolean;
}

const DimensionDialog = ({ open, isUnchanged }: Props) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const dimension = useAppSelector(
    (state) => state.audienceReducer.currentDimension
  );
  const updating = useAppSelector(
    (state) => state.audienceReducer.ui.dimensionUpdating
  );
  const currentAnswers = useAppSelector(
    (state) => state.audienceReducer.currentAnswers
  );

  const isSurvey = dimension && dimension.category === 'survey';

  React.useEffect(() => {
    if (!open && !isUnchanged) {
      dispatch(queryTotalAudienceSize());
    }
  }, [open]);

  React.useEffect(() => {
    window.onbeforeunload = () => {
      dispatchClose();
      return undefined;
    };
    return () => {
      window.onbeforeunload = null;
    };
  }, []);

  const modeled = useAppSelector((state) => {
    return state.audienceReducer.ui.modeled;
  });

  const isAddable = useAppSelector((state) => {
    if (dimension && dimension.category === 'survey') {
      return !!Object.keys(currentAnswers).length;
    } else if (
      dimension &&
      dimension.conditions &&
      dimension.conditions.length > 0
    ) {
      const validId = dimension.conditions.find((id) => {
        const condition = state.audienceReducer.currentConditions.byId[id];
        return (
          condition && condition.operator !== '' && condition.qualifiers !== ''
          //condition.audienceSize > 0
        );
      });

      return validId !== undefined;
    }

    return false;
  });

  const conditions: DimensionCategoryCondition[] = useAppSelector((state) => {
    const conditions: DimensionCategoryCondition[] = [];

    if (dimension && dimension.conditions) {
      dimension.conditions.forEach((id) => {
        if (
          !isSurvey ||
          (isSurvey &&
            state.audienceReducer.currentConditions.byId[id] &&
            state.audienceReducer.currentConditions.byId[id].option !==
              'question')
        ) {
          conditions.push(state.audienceReducer.currentConditions.byId[id]);
        }
      });
    }

    return conditions;
  });

  const dispatchClose = () => {
    const actionProps: ToggleDimensionDialogAction = {
      open: false,
    };
    dispatch(toggleDimensionDialog(actionProps));
    localStorage.removeItem('cacheData');
  };

  const handleApplyDimension = (dimension: Dimension) => () => {
    dispatch(applyDimension(dimension));
    dispatchClose();
  };

  const handleClose = () => {
    dispatchClose();
    localStorage.removeItem('cacheData');
  };

  const handleOperatorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const operator = event.target.value as DimensionOperator;
    if (dimension) {
      const updatedDimension: Dimension = {
        ...dimension,
        operator: operator,
      };
      dispatch(updateDimension(updatedDimension));
      dispatch(query());
    }
  };

  const renderDialogBody = () => {
    switch (dimension?.category) {
      case 'survey':
        return (
          <SurveyDialogBody dimension={dimension} conditions={conditions} />
        );
      default:
        return (
          <DimensionDialogBody
            dimension={dimension}
            handleOperatorChange={handleOperatorChange}
            conditions={conditions}
          />
        );
    }
  };

  const isNew = useAppSelector((state) => {
    if (dimension) {
      return (
        state.audienceReducer.currentDimensions.byId[dimension.id] === undefined
      );
    }
  });

  const hasFilters =
    dimension &&
    (dimension.category === 'transactions' || dimension.category === 'product');

  return (
    <>
      <ThemeProvider theme={dialog}>
        <Dialog
          className={classes.root}
          fullWidth={true}
          maxWidth="lg"
          onClose={handleClose}
          open={open}
          headerID="dimensionDialogHeader"
          header={dimension && <Title dimension={dimension} />}
          bodyID="dimensionDialogBody"
          body={
            <>
              {renderDialogBody()}
              {hasFilters && <TransactionFilters />}
            </>
          }
          footer={
            <Grid container alignItems="center">
              <Grid
                item
                xs={8}
                container
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                }}
              >
                <Grid
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: 8,
                  }}
                >
                  <Users size={22} />
                </Grid>
                <Grid
                  item
                  style={{
                    marginRight: 8,
                  }}
                >
                  <Typography
                    display="inline"
                    style={{
                      fontFamily: 'Calibre-Regular, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 'normal',
                      fontSize: 18,
                    }}
                  >
                    Estimated audience size:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    display="inline"
                    style={{
                      fontFamily: 'Calibre-Regular, sans-serif',
                      fontStyle: 'normal',
                      fontWeight: 600,
                      fontSize: 22,
                    }}
                  >
                    {updating ? (
                      <CircularProgress size={20} className={classes.spinner} />
                    ) : dimension && dimension.audienceSize === 0 ? (
                      '0'
                    ) : dimension && dimension.audienceSize ? (
                      Math.floor(dimension.audienceSize).toLocaleString()
                    ) : null}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={4}
                container
                alignItems="center"
                justifyContent="flex-end"
                spacing={1}
              >
                <Grid item>
                  {isNew && (
                    <Button
                      color="primary"
                      onClick={handleClose}
                      variant="outlined"
                    >
                      Cancel
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  {isNew && (
                    <Button
                      color="primary"
                      disabled={!isAddable}
                      onClick={handleApplyDimension(dimension)}
                      variant="contained"
                    >
                      Add Category
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          }
        />
      </ThemeProvider>
    </>
  );
};

interface TitleProps {
  dimension: Dimension;
}

const Title = ({ dimension }: TitleProps) => {
  const classes = useStyles();

  return (
    <ThemeProvider theme={icon}>
      <Grid container item xs={12} alignItems="center">
        <DimensionIcon category={dimension.category} size={24} />
        <Grid
          item
          container
          display="flex"
          alignItems="center"
          xs={11}
          style={{ paddingLeft: 16 }}
        >
          <Grid item>
            <Typography
              component="h3"
              variant="h6"
              className={classes.headerMainText}
            >
              {_.startCase(dimension.category)}
            </Typography>
          </Grid>
          <Grid item>
            <Typography
              component="h2"
              variant="caption"
              className={classes.headerSubText}
            >
              (Add Dimension Category)
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

interface DimensionDialogBodyProps {
  dimension: Dimension | undefined;
  handleOperatorChange: any;
  conditions: DimensionCategoryCondition[] | undefined;
}

const DimensionDialogBody = ({
  dimension,
  handleOperatorChange,
  conditions,
}: DimensionDialogBodyProps) => {
  const classes = useStyles();

  return (
    <Box style={{ margin: '15px 0px 15px 15px' }}>
      <Grid
        item
        className={classes.row}
        style={{ marginLeft: '10%', marginBottom: 10 }}
      >
        <Grid className={classes.tags} style={{ width: 208 }}>
          CONDITION
        </Grid>
        <Grid className={classes.tags} style={{ width: 108 }}>
          OPERATOR
        </Grid>
        <Grid className={classes.tags}>QUALIFIER / VALUE</Grid>
      </Grid>
      <Grid container alignItems="center">
        <Grid item style={{ minWidth: 91 }}>
          {dimension && (
            <FormControl
              color="primary"
              variant="outlined"
              className={classes.operatorControl}
              size="small"
            >
              <Select
                labelId="operator-label"
                id="operator-select"
                value={dimension.operator}
                onChange={handleOperatorChange}
                className={classes.selectBox}
                variant="outlined"
              >
                {DimensionOperators.map((operator) => {
                  return (
                    <MenuItem key={operator} value={operator}>
                      {_.startCase(operator)}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          )}
        </Grid>
        <Grid item className={classes.divider}>
          {dimension && conditions && (
            <DimensionConditions
              dimension={dimension}
              conditions={conditions}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

interface SurveyDimensionDialogBodyProps {
  dimension: Dimension | undefined;
  conditions: DimensionCategoryCondition[];
}

const SurveyDialogBody = ({
  dimension,
  conditions,
}: SurveyDimensionDialogBodyProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [countsLoading, setCountsLoading] = useState(true);

  const questions = useAppSelector((state) => state.audienceReducer.surveys);
  const currentConditions = useAppSelector(
    (state) => state.audienceReducer.currentConditions
  );

  useEffect(() => {
    //check if there is a question condition for this survey dimension
    if (
      dimension &&
      dimension.category === 'survey' &&
      dimension.conditions.length &&
      currentConditions.byId[dimension.conditions[0]]
    ) {
      const questionCondition = currentConditions.byId[dimension.conditions[0]];

      //set the current survey object as the current question condition
      if (typeof questionCondition.qualifiers === 'string') {
        dispatch(
          updateCurrentSurvey(questions.byId[questionCondition.qualifiers])
        );
      }

      const currentAnswerConditions = [];

      //find the answer conditions to the question conditions
      for (let i = 1; i < dimension.conditions.length; i++) {
        currentAnswerConditions.push(
          currentConditions.byId[dimension.conditions[i]]
        );
      }

      //set the current answers using the current answer conditions
      currentAnswerConditions.forEach((answerCondition) => {
        if (typeof answerCondition.qualifiers === 'string') {
          dispatch(populateCurrentAnswers(answerCondition.qualifiers));
        }
      });
    }
  }, []);

  const handleChangeQuestion = (questionid: string) => {
    dispatch(resetCurrentConditions());
    dispatch(updateCurrentSurvey(questions.byId[questionid]));
    dispatch(resetCurrentAnswers());
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={1}>
        <Grid item xs={4} direction="column">
          <div>Question</div>
          <QuestionSelector handleQuestionChange={handleChangeQuestion} />
        </Grid>
        <Grid item xs={8} direction="column">
          <AnswersSelector
            countsLoading={countsLoading}
            setCountsLoading={setCountsLoading}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

interface QuestionSelectorProps {
  handleQuestionChange: any;
}

const QuestionSelector = ({ handleQuestionChange }: QuestionSelectorProps) => {
  const classes = useStyles();
  const [inputValue, setInputValue] = useState<string | null>('');
  const [options, setOptions] = useState<string[]>([]);

  const questions = useAppSelector((state) => state.audienceReducer.surveys);
  const questiondesc =
    [...questions.allIds].sort((a, b) => Number(b) - Number(a)) || [];

  const currentSurvey = useAppSelector(
    (state) => state.audienceReducer.currentSurvey
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(querySurveys());
  }, []);

  useEffect(() => {
    const resultIds = [];
    if (inputValue) {
      for (const qId of [...questiondesc]) {
        const { questionText } = questions.byId[qId];
        const displayText = `${qId.toUpperCase()}. ${questionText.toUpperCase()}`;
        if (displayText.includes(inputValue.toUpperCase())) {
          resultIds.push(qId);
        }
      }

      setOptions(resultIds);
    } else {
      setOptions(questiondesc);
    }
  }, [inputValue, questions.byId]);

  return (
    <FormControl
      color="primary"
      variant="outlined"
      className={classes.formControl}
    >
      <ThemeProvider theme={select}>
        <Styled.SelectAutoCompleteGroup
          blurOnSelect
          style={{ width: 300 }}
          value={currentSurvey?.id || ''}
          inputValue={inputValue || ''}
          options={options}
          popupIcon={<CaretDown size={20} />}
          getOptionLabel={(option) => {
            return option
              ? `${option}. ${questions.byId[option].questionText}`
              : '';
          }}
          onChange={(e, newValue) => {
            handleQuestionChange(newValue);
            setInputValue(newValue || '');
          }}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue || '');
          }}
          renderInput={(params) => {
            return <TextFieldSearch {...params} variant="outlined" />;
          }}
          renderOption={(props, option) => {
            const { id: qId, questionText } = questions.byId[option];
            return (
              <Styled.SelectAutoCompleteGroupOptions>
                <li {...props} key={qId}>{`${qId}. ${questionText}`}</li>
              </Styled.SelectAutoCompleteGroupOptions>
            );
          }}
        />
      </ThemeProvider>
    </FormControl>
  );
};

interface AnswersSelectorProps {
  countsLoading: boolean;
  setCountsLoading: any;
}

const AnswersSelector = ({
  countsLoading,
  setCountsLoading,
}: AnswersSelectorProps) => {
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentSurvey = useAppSelector(
    (state) => state.audienceReducer.currentSurvey
  );
  const selectedAnswers = useAppSelector(
    (state) => state.audienceReducer.currentAnswers
  );
  const currentResponses = useAppSelector(
    (state) => state.audienceReducer.currentResponses
  );
  const currentDimension = useAppSelector(
    (state) => state.audienceReducer.currentDimension
  );

  const modeled = useAppSelector((state) => {
    return state.audienceReducer.ui.modeled;
  });

  useEffect(() => {
    if (currentSurvey) {
      axios
        .get(`${API_BASE_ADDRESS}/questions/${currentSurvey?.id}`)
        .then((res) => {
          const payload = res.data.responses;
          const { responses, totalCount } = payload;
          const formattedResponse: currentCounts = formatResponse(
            responses,
            totalCount
          );
          dispatch(updateCurrentResponses(formattedResponse));
          setCountsLoading(false);
        })
        .catch((err) => {
          setCountsLoading(false);
          console.log(err);
        });
    }
  }, [currentSurvey?.id]);

  useEffect(() => {
    if (currentDimension) {
      let totalAudienceSize = 0;
      Object.keys(selectedAnswers).forEach((answerid) => {
        if (selectedAnswers[answerid] && currentResponses) {
          totalAudienceSize += currentResponses[answerid];
        }
      });
      const cloned = _.cloneDeep(currentDimension);
      cloned.audienceSize = Math.floor(Number(totalAudienceSize));
      dispatch(updateCurrentDimension(cloned));
    }
  }, [currentResponses]);

  const formatResponse = (response: any, totalCount: number) => {
    const returnObj: currentCounts = {};
    Object.keys(response).forEach((key) => {
      returnObj[key] = Math.floor((Number(response[key]) * totalCount) / 100);
    });
    return returnObj;
  };

  const handleAddAnswer = (answerid: string) => {
    dispatch(updateCurrentAnswers(answerid));
  };

  if (currentSurvey) {
    return (
      <List>
        <ListSubheader
          className={classes.listSubheader}
          sx={{
            fontWeight: 'bold',
            lineHeight: 'normal',
            fontFamily: 'Calibre-Semibold, sans-serif',
            fontSize: 18,
          }}
        >
          Answer
        </ListSubheader>
        {currentSurvey?.answers.map((answer) => {
          const labelId = `checkbox-list-label-${answer.id}`;
          let currentCount;
          if (currentResponses) {
            if (currentResponses[answer.id]) {
              currentCount = currentResponses[answer.id];
            } else {
              currentCount = 0;
            }
          }
          return (
            <ListItem key={answer.id} className={classes.surveyAnswerListItem}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                wrap="nowrap"
              >
                <Grid item className={classes.answerText}>
                  {answer.answerText}
                </Grid>
                <Grid item>
                  <Grid
                    container
                    direction="row"
                    wrap="nowrap"
                    alignItems="center"
                  >
                    <Grid item>
                      <ThemeProvider theme={checkbox}>
                        <Checkbox
                          edge="start"
                          checked={!!selectedAnswers[answer.id]}
                          value={answer.id}
                          onChange={(e) => handleAddAnswer(e.target.value)}
                          disabled={countsLoading}
                          tabIndex={-1}
                          disableRipple
                          inputProps={{ 'aria-labelledby': labelId }}
                          color="primary"
                        />
                      </ThemeProvider>
                    </Grid>
                    <Grid item className={classes.surveySpinner}>
                      <ThemeProvider theme={spinner}>
                        <Button
                          variant="contained"
                          disableElevation
                          disabled
                          size="small"
                          style={{
                            padding: '4px 6px 4px 6px',
                            borderRadius: 4,
                            backgroundColor: `${
                              countsLoading
                                ? tokens.colorButtonDark
                                : currentCount && currentCount > 0
                                ? tokens.colorButtonDark
                                : tokens.colorButtonGray10
                            }`,
                          }}
                        >
                          <Users size={20} fill="white" />
                          <Box
                            marginLeft={1}
                            style={{
                              color: tokens.colorFlesh,
                              fontStyle: 'normal',
                              fontWeight: 500,
                              fontSize: 13,
                            }}
                          >
                            {countsLoading ? (
                              <div className={classes.conditionSpinner}>
                                <CircularProgress />
                              </div>
                            ) : currentCount === 0 ? (
                              '0'
                            ) : modeled && currentCount ? (
                              numberFormatter(currentCount * MULTIPLIER, 1)
                            ) : (
                              numberFormatter(currentCount, 1)
                            )}
                          </Box>
                        </Button>
                      </ThemeProvider>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </ListItem>
          );
        })}
      </List>
    );
  } else {
    return <></>;
  }
};

export default DimensionDialog;
