import TextFieldSearch from 'components/TextFieldSearch/TextFieldSearch';
import { CaretDown } from '@phosphor-icons/react';
import { Grid, TextField } from '@klover/attain-design-system';
import * as Styled from './index.styles';

/**
 * DynamicForm Component
 * Renders a dynamic form based on the provided configuration.
 *
 * @param {Array} config - Array of control objects that define the form fields.
 * @param {Function} onChange - Callback function to handle changes in the form fields.
 */
const DynamicForm: React.FC<DynamicFormProps> = ({ config = [], onChange }) => {
  /**
   * Handles the change event for form fields.
   * @param {string} id - The unique identifier of the form field.
   * @param {string} value - The updated value of the form field.
   */
  const handleChange = (id: string, value: string | null) => {
    onChange(id, value);
  };

  /**
   * Renders the appropriate control based on the control type.
   * @param {Object} control - The control object defining the form field.
   * @returns {JSX.Element | null} The rendered form control or null if the type is not supported.
   */
  const renderFormControl = (control: FormControl): JSX.Element | null => {
    const {
      type,
      id,
      label,
      options = [],
      value,
      rows = 4,
      placeholder,
      disabled = false,
      regex = '',
      ...otherProps
    } = control;

    switch (type) {
      case 'label':
        return <Styled.DividerLabel>{label}</Styled.DividerLabel>;

      case 'textbox':
        return (
          <TextField
            key={id}
            id={id}
            label={label}
            value={value || ''}
            onKeyDown={(e) => {
              if (regex) {
                const value = e.key;
                const regexObj = new RegExp(regex);
                if (value !== 'Backspace' && !regexObj.test(value))
                  e.preventDefault();
              }
            }}
            onChange={(e) => handleChange(id, e.target.value)}
            fullWidth
            placeholder
            disabled={disabled}
            {...otherProps}
          />
        );

      case 'textarea':
        return (
          <TextField
            key={id}
            id={id}
            label={label}
            value={value || ''}
            onChange={(e) => handleChange(id, e.target.value)}
            multiline
            rows={rows}
            fullWidth
            disabled={disabled}
            {...otherProps}
          />
        );

      case 'autocomplete':
        return (
          <Styled.SelectAutoCompleteGroup
            key={id}
            id={id}
            value={value || null}
            onChange={(e, newValue) => handleChange(id, newValue)}
            displayEmpty
            popupIcon={<CaretDown />}
            fullWidth
            options={options}
            disabled={disabled}
            style={{ marginTop: 30 }}
            renderInput={(params) => (
              <TextFieldSearch
                {...params}
                placeholder={placeholder}
                variant="outlined"
              />
            )}
            renderOption={(props, option) => (
              <Styled.SelectAutoCompleteGroupOptions>
                <li {...props} key={option.id}>
                  {option.label}
                </li>
              </Styled.SelectAutoCompleteGroupOptions>
            )}
            {...otherProps}
          />
        );

      default:
        return null;
    }
  };

  return (
    <Grid container>
      {config.map((control) => (
        <Grid item key={control.id} {...control.gridProps}>
          {renderFormControl(control)}
        </Grid>
      ))}
    </Grid>
  );
};

interface FormControlOption {
  id: string;
  label: string;
}

// Define type for individual form control
interface FormControl {
  id: string;
  type: 'label' | 'textbox' | 'textarea' | 'autocomplete'; // Supported control types
  label?: string;
  value?: string | null;
  rows?: number; // Number of rows (for textarea)
  placeholder?: string;
  options?: FormControlOption[];
  disabled?: boolean;
  regex?: string;
  gridProps?: Record<string, any>; // Props for the Grid container
  [key: string]: any; // Allow additional props
}

// Props type for DynamicForm component
interface DynamicFormProps {
  config: FormControl[]; // Array of form controls
  onChange: (id: string, value: string | null) => void; // Callback for handling changes
}

export default DynamicForm;
