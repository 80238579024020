import React, { useMemo, useState } from 'react';
import {
  Button,
  Dialog,
  Grid,
  MenuItem,
  Select,
  TextField,
} from '@klover/attain-design-system';
import {
  EXPORT_EMAIL_INFO_TEXT,
  ExportAudience,
  LoadingStates,
  REQUIRED_FIELDS_ARE_MISSING,
  SELECT_EXPORT_TYPE,
} from '../../../constants';
import {
  ToggleExportDialogAction,
  addNotification,
  exportAudience,
  fetchAudience,
  toggleAudienceExporting,
  toggleExportDialog,
} from '../slice';
import { useAppDispatch, useAppSelector } from 'redux/hooks';
import { useUser } from 'reactfire';
// Material UI Components
import AudienceExport from '../../../components/AudienceExport';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import Typography from '@material-ui/core/Typography';
import axios from 'axios';
import tokens from 'tokens';
import { API_BASE_ADDRESS } from '../variables';
import { CircularProgress, Tooltip } from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { isFloat, isSegmentNameValid } from '../../../helper/helper';
import { useFlags } from 'launchdarkly-react-client-sdk';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    divider: {
      borderLeft: '0.2em solid ' + tokens.colorDividerLgBorder,
    },
    email: {
      marginTop: '24px',
      marginBottom: theme.spacing(3),
      marginLeft: '6px',
    },
    title: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '32px',
      minWidth: 1000,
    },
    selectExport: {
      width: '100%',
    },
    controlLabel: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: 4,
    },
    emailLabel: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: 10,
      paddingTop: 40,
    },
  })
);

interface Props {
  open: boolean;
  lalModelType: string;
}

const AudienceExportDialog = ({ open, lalModelType }: Props) => {
  const classes = useStyles();
  const {
    uiAudienceManagerExportLiveRampVisible = false,
    uiAudienceManagerExportTTDDataAllianceVisible = false,
  } = useFlags();
  const flags = useFlags();
  const dispatch = useAppDispatch();
  const audience = useAppSelector((state) => {
    if (state.audienceReducer && state.audienceReducer.currentAudience) {
      return state.audienceReducer.currentAudience;
    } else {
      return null;
    }
  });

  const getSelectTypeOptions = () => {
    const selectOptions = [
      { text: ExportAudience.KLOVER_IDS, value: 'klover_ids' },
      // { text: ExportAudience.KLOVER_RAMP_IDS, value: 'rampid' },
      { text: ExportAudience.TRANSUNION, value: 'transunion' },
      // { text: ExportAudience.TRANSUNION_TTD, value: 'transunion_ttd' },
      // { text: ExportAudience.TRANSUNION_HEMS, value: 'transunion_hems' },
      { text: ExportAudience.CUSTOM_SEGMENT, value: 'custom_segment' },
      { text: ExportAudience.OTS_SEGMENT, value: 'ots_segment' },
    ];

    if (uiAudienceManagerExportLiveRampVisible)
      selectOptions.push({
        text: ExportAudience.LIVERAMP_CUSTOM_SEGMENT,
        value: 'liveramp_custom_segment',
      });

    if (uiAudienceManagerExportTTDDataAllianceVisible)
      selectOptions.push({
        text: ExportAudience.TTD_DATA_ALLIANCE,
        value: 'ttd_data_aliance',
      });
    return selectOptions;
  };

  const { data: user } = useUser();
  const currentUser = user || { email: '' };
  const [emails, setEmails] = useState(currentUser.email);
  const [isExporting, setIsExporting] = useState(false);
  const [selectedExportType, setSelectedExportType] = useState('');
  const [segmentName, setSegmentName] = useState('');
  const [description, setDescription] = useState('');
  const [cpm, setCpm] = useState('');
  const [percentageOfMedia, setPercentageOfMedia] = useState('');
  const [partnerID, setPartnerID] = useState('');
  const [advertiserID, setAdvertiserID] = useState('');
  const [platformDestination, setPlatformDestination] = useState('');
  const [advertiserDestination, setAdvertiserDestination] = useState('');
  const [tvTargeting, setTvTargeting] = useState('');
  const [advertiserDirect, setAdvertiserDirect] = useState('');

  const dispatchClose = () => {
    const actionProps: ToggleExportDialogAction = {
      open: false,
    };
    dispatch(toggleExportDialog(actionProps));
  };

  const handleEmailsChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const emails = event.target.value as string;
    setEmails(emails);
  };

  const handleSegmentName = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as string;
    setSegmentName(data);
  };

  const handleDescription = (event: React.ChangeEvent<{ value: unknown }>) => {
    const data = event.target.value as string;
    setDescription(data);
  };

  const handleCpm = (event: any) => {
    setCpm(event.target.value);
  };

  const handlePercentageOfMedia = (event: any) => {
    setPercentageOfMedia(event.target.value);
  };

  const handlePartnerID = (event: any) => {
    setPartnerID(event.target.value);
  };

  const handleAdvertiserID = (event: any) => {
    setAdvertiserID(event.target.value);
  };

  const handleTvTargeting = (event: any) => {
    setTvTargeting(event.target.value);
  };

  const handleAdvertiserDirect = (event: any) => {
    setAdvertiserDirect(event.target.value);
  };

  const handleExport = (columns: Array<string>) => {
    if (emails && emails !== '') {
      try {
        setIsExporting(true);
        dispatch(toggleAudienceExporting(true));
        dispatch(exportAudience({ emails, columns }));
        dispatch(fetchAudience({ id }));
        dispatchClose();
        setIsExporting(false);
      } catch (e) {
        setIsExporting(false);
      }
    }
  };

  const handleExportToTransunion = (
    exportType: string,
    platformIntegrated: string
  ) => {
    if (audience) {
      const { id, name } = audience;
      if (cpm !== '' && !isFloat(cpm)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: 'Please enter valid CPM',
          })
        );
        return;
      }
      if (percentageOfMedia !== '' && !isFloat(percentageOfMedia)) {
        dispatch(
          addNotification({
            state: LoadingStates.ERROR,
            message: 'Please enter valid % of media',
          })
        );
        return;
      }
      const payload = {
        id,
        name,
        emails: currentUser.email,
        exportType,
        segmentName,
        description,
        cpm: cpm !== '' ? parseFloat(cpm) : '',
        percentageOfMedia:
          percentageOfMedia !== '' ? parseFloat(percentageOfMedia) : '',
        partnerID,
        advertiserID,
        platformIntegrated,
        platformDestination: platformDestination,
        advertiserDestination: advertiserDestination,
        tvCpm: tvTargeting !== '' ? parseFloat(tvTargeting) : '',
        lalModelType,
      };

      setIsExporting(true);
      axios
        .post(`${API_BASE_ADDRESS}/audience-manager/export/transunion`, [
          payload,
        ])
        .then(() => {
          setIsExporting(false);
          dispatchClose();
          clearData();
          setSelectedExportType('');
          dispatch(
            addNotification({
              state: 'done',
              message: `Your audience is being exported to TransUnion`,
            })
          );

          setTimeout(() => {
            dispatch(fetchAudience({ id }));
          }, 3000);
        })
        .catch(() => {
          setIsExporting(false);
          dispatchClose();
          setSelectedExportType('');
          clearData();
          dispatch(
            addNotification({
              state: 'error',
              message: `Your audience failed to export to TransUnion`,
            })
          );
        });
    }
  };

  const startExport = () => {
    if (selectedExportType === 'klover_ids') {
      handleExport(['kloverid']);
    } else if (selectedExportType === 'rampid') {
      handleExport(['kloverid', 'rampid']);
    } else if (selectedExportType === 'transunion') {
      handleExportToTransunion('transunion', '');
    } else if (selectedExportType === 'transunion_ttd') {
      handleExportToTransunion('transunion_ttd', '');
    } else if (selectedExportType === 'transunion_hems') {
      handleExportToTransunion('transunion_hems', '');
    } else if (selectedExportType === 'custom_segment') {
      handleExportToTransunion('custom_segment', 'trade_desk');
    } else if (selectedExportType === 'ots_segment') {
      handleExportToTransunion('ots_segment', 'trade_desk');
    } else if (selectedExportType === 'liveramp_custom_segment') {
      handleExportToTransunion('liveramp_custom_segment', 'liveramp');
    } else if (selectedExportType === 'ttd_data_aliance') {
      handleExportToTransunion('ttd_data_aliance', 'trade_desk');
    }
  };

  const clearData = () => {
    setSegmentName('');
    setDescription('');
    setCpm('');
    setPercentageOfMedia('');
    setPartnerID('');
    setAdvertiserID('');
  };

  const handleClose = () => {
    setSelectedExportType('');
    clearData();
    dispatchClose();
  };

  const {
    isValid: isValidSegmentName,
    validationMessage: inValidSegmentNameErrorMessage,
  } = isSegmentNameValid([selectedExportType], segmentName);

  const isRequiredFieldMissing = useMemo(() => {
    if (selectedExportType === 'custom_segment') {
      if (
        segmentName === '' ||
        cpm === '' ||
        percentageOfMedia === '' ||
        (partnerID === '' && advertiserID === '')
      ) {
        return true;
      }
    } else if (
      selectedExportType === 'ots_segment' ||
      selectedExportType === 'ttd_data_aliance'
    ) {
      if (segmentName === '' || cpm === '' || percentageOfMedia === '') {
        return true;
      }
    } else if (selectedExportType === 'liveramp_custom_segment') {
      if (
        segmentName === '' ||
        cpm === '' ||
        tvTargeting === '' ||
        platformDestination === '' ||
        advertiserDestination === ''
      ) {
        return true;
      }
    }
    return false;
  }, [
    selectedExportType,
    segmentName,
    cpm,
    percentageOfMedia,
    partnerID,
    advertiserID,
    platformDestination,
    advertiserDestination,
    tvTargeting,
  ]);

  return (
    <>
      <Dialog
        className={classes.root}
        onClose={handleClose}
        open={open}
        maxWidth="lg"
        disableEnforceFocus
        headerID="audienceExportHeader"
        header={
          <Typography variant="h2" className={classes.title}>
            Export Audience
          </Typography>
        }
        bodyID="audienceExportBody"
        body={
          <DialogContent style={{ overflow: 'hidden' }}>
            <Grid item xs={12}>
              <FormControl className={classes.selectExport}>
                <div className={classes.controlLabel}>{SELECT_EXPORT_TYPE}</div>
                <Select
                  labelId="export-type-label"
                  id="export-type"
                  value={selectedExportType}
                  onChange={(event: SelectChangeEvent) => {
                    clearData();
                    setSelectedExportType(event.target.value as string);
                  }}
                >
                  {getSelectTypeOptions().map((item) => {
                    return (
                      <MenuItem key={item.value} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
            {(selectedExportType === 'custom_segment' ||
              selectedExportType === 'ots_segment' ||
              selectedExportType === 'liveramp_custom_segment' ||
              selectedExportType === 'ttd_data_aliance') && (
              <AudienceExport
                selectedExportType={selectedExportType}
                handleSegmentName={handleSegmentName}
                segmentName={segmentName}
                isSegmentNameValid={isValidSegmentName}
                inValidSegmentNameErrorMessage={inValidSegmentNameErrorMessage}
                handleDescription={handleDescription}
                description={description}
                handleCpm={handleCpm}
                cpm={cpm}
                handlePercentageOfMedia={handlePercentageOfMedia}
                percentageOfMedia={percentageOfMedia}
                handlePartnerID={handlePartnerID}
                partnerID={partnerID}
                advertiserID={advertiserID}
                handleAdvertiserID={handleAdvertiserID}
                platformDestination={platformDestination}
                setPlatformDestination={setPlatformDestination}
                advertiserDestination={advertiserDestination}
                setAdvertiserDestination={setAdvertiserDestination}
                tvTargeting={tvTargeting}
                handleTvTargeting={handleTvTargeting}
                advertiserDirect={advertiserDirect}
                handleAdvertiserDirect={handleAdvertiserDirect}
              />
            )}
            {(selectedExportType === 'klover_ids' ||
              selectedExportType === 'rampID') && (
              <>
                <div className={classes.emailLabel}>
                  {EXPORT_EMAIL_INFO_TEXT}
                </div>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      className={classes.email}
                      onChange={handleEmailsChange}
                      value={emails}
                      fullWidth
                      id="email"
                      label="Email Address"
                      type="email"
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </DialogContent>
        }
        footer={
          <DialogActions>
            <Button color="primary" onClick={handleClose} variant="outlined">
              Cancel
            </Button>
            <Tooltip
              title={isRequiredFieldMissing ? REQUIRED_FIELDS_ARE_MISSING : ''}
              placement="top"
              arrow
            >
              <span>
                <Button
                  color="primary"
                  disableElevation
                  onClick={() => startExport()}
                  variant="contained"
                  disabled={
                    !selectedExportType ||
                    isExporting ||
                    (selectedExportType === 'klover_ids' && !emails) ||
                    (selectedExportType === 'rampID' && !emails) ||
                    isRequiredFieldMissing ||
                    !isValidSegmentName
                  }
                >
                  {isExporting ? <CircularProgress size={20} /> : 'Export'}
                </Button>
              </span>
            </Tooltip>
          </DialogActions>
        }
      ></Dialog>
    </>
  );
};

export default AudienceExportDialog;
