import axios from 'axios';
import { API_BASE_ADDRESS } from '../variables';
import { call, put, takeLatest } from 'redux-saga/effects';
import {
  Audience,
  Condition,
  Dimension,
  DimensionCategoryCondition,
  DimensionGroup,
  ToggleDuplicateAudienceDialogAction,
  addNotification,
  clearCurrentAudience,
  createAudience,
  deleteAudience,
  duplicateAudience,
  fetchAudience,
  loadOriginalAudience,
  querySurveys,
  removeAudience,
  removeDimensionGroups,
  removeDimensionsAndConditions,
  removeStates,
  saveAudience,
  searchAudiences,
  setAudienceLoadingState,
  setGroupLoadingState,
  setSurveyLoading,
  setUIState,
  toggleDraftStateDialog,
  toggleDuplicateAudienceDialog,
  updateAudiences,
  updateAudiencesEntities,
  updateConditionsEntities,
  updateCurrentAudience,
  updateCurrentConditions,
  updateCurrentDimensionGroups,
  updateCurrentDimensions,
  updateCurrentGroupAudiences,
  updateDimensionGroupsEntities,
  updateDimensionsEntities,
  updateStates,
  updateSurveys,
} from '../slice';

import _ from 'lodash';
import camelizeKeys from 'utils/camelizeKeys';
import snakeifyKeys from 'utils/snakeifyKeys';
import { LoadingStates } from 'constants';
import { NormalizedObjects } from 'interfaces/entities';
import { PayloadAction } from '@reduxjs/toolkit';
import { auth } from 'utils/firebase';
import { getById } from 'utils/byId';
import { removeEmpty } from 'utils/removeEmpty';
import { splitAudience } from 'utils/splitAudience';

const API_ENDPOINT = `${API_BASE_ADDRESS}/audience-manager/audiences`;

interface AudienceGroupParams {
  id: string;
  name: string;
}

interface DuplicateAudienceParams {
  audience: Audience;
  name: string;
}

interface NormalizedObjectsProps {
  updatedAudience: Audience;
  currentDimensions: NormalizedObjects<Dimension>;
  currentDimensionGroups: NormalizedObjects<DimensionGroup>;
  currentConditions: NormalizedObjects<DimensionCategoryCondition>;
}

const sortAudienceDimensions = (audience) => {
  const cloneOfAudience = _.clone(audience);
  // manually sorting
  if (cloneOfAudience.dimensions) {
    cloneOfAudience.dimensions = cloneOfAudience.dimensions.sort(
      (a, b) => a.sort - b.sort
    );
  }
  return cloneOfAudience;
};

function handleDuplicateAudience({ audience, name }: DuplicateAudienceParams) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(
      `${API_ENDPOINT}/${audience.id}/duplicate`,
      {
        name,
        created_by_email: audience.created_by_email,
        updated_by_email: audience.updated_by_email,
      }
    );
  });
}

function handleFetchAudience(audience: AudienceGroupParams) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(`${API_ENDPOINT}/${audience.id}`);
  });
}

function handleSearchAudiences(query: any) {
  const { page, rowsPerPage, search, sortOrder, sortBy, groupId, email } = query || {}
  const group = groupId === 'all' ? '' : groupId;
  const emailAddress = email === 'all' ? '' : email;
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      `${API_ENDPOINT}?search=${encodeURIComponent(search)}&page=${page}&rowsPerPage=${rowsPerPage}&sortBy=${sortBy}&sortOrder=${sortOrder}&groupId=${group}&email=${emailAddress}`
    );
  });
}

function handleFetchSurvey(questionid: string) {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      `${API_BASE_ADDRESS}/audience-manager/survey/${questionid}`
    );
  });
}

function handleFetchSurveys() {
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(
      `${API_BASE_ADDRESS}/audience-manager/survey`
    );
  });
}

function* watchQuerySurvey(action: PayloadAction<Condition>) {
  try {
    const surveysRes = yield call(handleFetchSurveys, null);
    const surveys = surveysRes.data;
    yield put(updateSurveys(surveys));
  } catch (e) {
    yield put(
      setUIState({
        value: true,
        uiPropertyName: 'errorFetchisng',
      })
    );
    // setting a notification for failure
    yield put(
      addNotification({
        state: 'error',
        message: `Failed to Retrieve Survey ${action.payload.id}`,
      })
    );
  }
}

function* loadCurrentAudienceAndUpdateEntities(data: Audience) {
  const { audience, dimensions, dimensionGroups, conditions } =
    splitAudience(data);
  yield put(updateAudiencesEntities(audience));
  yield put(updateDimensionsEntities(dimensions));
  yield put(updateDimensionGroupsEntities(dimensionGroups));
  yield put(updateConditionsEntities(conditions));

  yield put(updateCurrentAudience(audience));
  yield put(updateCurrentDimensions(dimensions));
  yield put(updateCurrentDimensionGroups(dimensionGroups));
  yield put(updateCurrentConditions(conditions));
  yield put(updateCurrentGroupAudiences(audience));
}

function* removeAudienceAndUpdateEntities(audience: Audience) {
  yield put(removeAudience(audience));
  if (audience.dimensions && audience.dimensions.length)
    yield put(removeDimensionsAndConditions(audience));
  if (audience.dimensionGroups && audience.dimensionGroups.length)
    yield put(removeDimensionGroups(audience));
}

export function buildAudienceObject(normalizedObjects: NormalizedObjectsProps) {
  const {
    updatedAudience,
    currentDimensions,
    currentDimensionGroups,
    currentConditions,
  } = normalizedObjects;

  const audience = _.omit(
    updatedAudience,
    'created_at',
    'createdAt',
    'updatedAt',
    'updated_at',
    'dimensions',
    'dimensionGroups'
  );

  function removeFromAll(obj: any) {
    return removeEmpty(
      _.omit(
        obj,
        'created_at',
        'createdAt',
        'updatedAt',
        'updated_at',
        'productName'
      )
    );
  }

  const conditions = Object.values(currentConditions.byId)
    .map((condition) => removeFromAll(condition))
    .filter((x) => x.qualifiers.length);
  const conditionsById = getById(conditions, 'id');

  const dimensions = Object.values(currentDimensions.byId).map((dimension) =>
    removeFromAll(dimension)
  );

  dimensions.forEach((dimension) => {
    for (const key in dimension) {
      if (dimension[key] === '') dimension[key] = null;
    }
    const selectedConditions = [];
    dimension.conditions.forEach((condition) => {
      const conditionObject = conditionsById[condition];
      if (conditionObject) {
        for (const key in conditionObject) {
          if (condition[key] === '') condition[key] = null;
        }
        if (!conditionObject.merchant) conditionObject.merchant = null;

        if (!conditionObject.amount) conditionObject.amount = null;
        if (dimension.amount === 'custom' && dimension.amountCustom) {
          conditionObject.amount = `${dimension.amount}:${dimension.amountCustom}`;
        }
        delete conditionObject.amountCustom;

        if (!conditionObject.count) conditionObject.count = null;
        if (dimension.count === 'custom' && dimension.countCustom) {
          conditionObject.count = `${dimension.count}:${dimension.countCustom}`;
        }
        delete conditionObject.countCustom;

        if (!conditionObject.timeframe) conditionObject.timeframe = null;
        if (
          dimension.timeframeOperator &&
          dimension.timeframeOperator === 'custom' &&
          dimension.timeframeStart &&
          dimension.timeframeEnd
        ) {
          conditionObject.timeframeOperator = dimension.timeframeOperator;
          conditionObject.timeframe = `${dimension.timeframeOperator}:${dimension.timeframeStart}-${dimension.timeframeEnd}`;
        } else if (dimension.timeframeOperator) {
          conditionObject.timeframeOperator = dimension.timeframeOperator;
        } else {
          conditionObject.timeframeOperator = null;
        }
        delete conditionObject.timeframeStart;
        delete conditionObject.timeframeEnd;

        if (dimension.amountOperator) {
          conditionObject.amountOperator = dimension.amountOperator;
        } else {
          conditionObject.amountOperator = null;
        }
        if (dimension.countOperator) {
          conditionObject.countOperator = dimension.countOperator;
        } else {
          conditionObject.countOperator = null;
        }
        if (conditionsById[condition]) selectedConditions.push(conditionObject);
      }
    });
    dimension.conditions = selectedConditions;
    if (!dimension.timeframe) dimension.timeframe = null;
    if (!dimension.count) dimension.count = null;
    if (!dimension.amount) dimension.amount = null;
    if (!dimension.amountOperator) dimension.amountOperator = null;
    if (!dimension.countOperator) dimension.countOperator = null;
    if (!dimension.timeframeOperator) dimension.timeframeOperator = null;

    if (dimension.amount === 'custom' && dimension.amountCustom) {
      dimension.amount = `${dimension.amount}:${dimension.amountCustom}`;
    }
    delete dimension.amountCustom;

    if (dimension.count === 'custom' && dimension.countCustom) {
      dimension.count = `${dimension.count}:${dimension.countCustom}`;
    }
    delete dimension.countCustom;

    if (
      dimension.timeframeOperator === 'custom' &&
      dimension.timeframeStart &&
      dimension.timeframeEnd
    ) {
      dimension.timeframe = `${dimension.timeframeOperator}:${dimension.timeframeStart}-${dimension.timeframeEnd}`;
    }
    delete dimension.timeframeStart;
    delete dimension.timeframeEnd;
  });

  const dimension_groups = Object.values(currentDimensionGroups.byId).map(
    (dimensionGroup) => removeFromAll(dimensionGroup)
  );
  for (const dimension_group of dimension_groups) {
    for (const key in dimension_group) {
      if (dimension_group[key] === '') dimension_group[key] = null;
    }
  }
  for (const key in audience) {
    if (audience[key] === '') audience[key] = null;
  }
  audience.dimensions = dimensions;
  audience.dimension_groups = dimension_groups;
  return snakeifyKeys(removeEmpty(audience));
}

function handleCreateAudience(normalizedObjects: NormalizedObjectsProps) {
  const postData = buildAudienceObject(normalizedObjects);
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.post(`${API_ENDPOINT}`, {
      audience: postData,
      user: auth.currentUser?.email,
    });
  });
}

function handleUpdateAudience(normalizedObjects: NormalizedObjectsProps) {
  const putData = buildAudienceObject(normalizedObjects);
  const audienceId = putData.id;
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.put(
      `${API_ENDPOINT}/${audienceId}`,
      _.omit(putData, 'id')
    );
  });
}

function handleDeleteAudience(audience: any) {
  const { id: audienceId, updatedByEmail: user } = audience;
  return auth.currentUser.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.delete(
      `${API_ENDPOINT}/${audienceId}?user=${user}`
    );
  });
}

function* watchCreateAudience(action: PayloadAction<NormalizedObjectsProps>) {
  const { updatedAudience } = action.payload;
  try {
    // sets the audience loading state to loading
    yield put(setAudienceLoadingState({ state: LoadingStates.LOADING }));
    // actually creating the audience
    const result = yield call(handleCreateAudience, action.payload);
    const modifiedAudience = sortAudienceDimensions(result.data);
    yield put(loadOriginalAudience(modifiedAudience));
    const audienceid = modifiedAudience.id;
    window.history.pushState(
      null,
      document.title,
      `${window.location.href}/${audienceid}`
    );
    yield put(
      setUIState({
        value: false,
        uiPropertyName: 'errorFetching',
      })
    );

    // load current audience and entities with data that we get back
    yield call(loadCurrentAudienceAndUpdateEntities, modifiedAudience);
    // set the audience loading state to done
    yield put(setAudienceLoadingState({ state: LoadingStates.DONE }));
    // add a notification saying that we've succesfully created an audience
    yield put(
      addNotification({
        state: LoadingStates.DONE,
        message: `Successfully Created Audience ${updatedAudience.name}`,
      })
    );
    yield put(toggleDraftStateDialog({ open: false }));
  } catch (e) {
    // set the loading state to error
    yield put(setAudienceLoadingState({ state: LoadingStates.ERROR }));
    // set a notification saying that there was an error
    yield put(
      addNotification({
        state: LoadingStates.ERROR,
        message: `Failed to Create Audience ${updatedAudience.name}`,
      })
    );
  }
}

function* watchSearchAudiences(action) {
  try {
    yield put(setGroupLoadingState({ state: LoadingStates.LOADING }));
    // const search = action.payload;
    const result = yield call(handleSearchAudiences, action.payload);
    const audiences = result.data.map((elem) => {
      const audience: Audience = {
        ...camelizeKeys(elem),
        dimensions: [],
        dimensionGroups: [],
      };
      return audience;
    });
    yield put(updateAudiences(audiences));
    yield put(setGroupLoadingState({ state: LoadingStates.DONE }));
  } catch (e) {
    console.log('Error:', e);
  }
}

function* watchFetchAudience(action: PayloadAction<Audience>) {
  try {
    // loading the current Audience with a 'fake' audience with no dimensions and dimensiongroups so that user can see the name of audience
    yield put(
      updateCurrentAudience(
        _.assign(_.clone(action.payload), {
          dimensions: [],
          dimensionGroups: [],
        })
      )
    );
    // set the state of audience to loading
    yield put(setAudienceLoadingState({ state: LoadingStates.LOADING }));
    // actually fetching the audience
    const result = yield call(handleFetchAudience, action.payload);
    const modifiedAudience = sortAudienceDimensions(result.data);
    //if one of the dimensions is a survey, update and fetch survey data
    let hasSurvey = false;
    modifiedAudience.dimensions.forEach((dimension: any) => {
      if (dimension.category === 'survey') {
        hasSurvey = true;
        dimension.conditions.sort(
          (conditionA: any, conditionB: any) =>
            conditionA.sort - conditionB.sort
        );
      }
    });
    if (hasSurvey) {
      const surveysRes = yield call(handleFetchSurveys, null);
      const surveys = surveysRes.data;
      yield put(updateSurveys(surveys));
    }
    // manually sorting
    yield put(loadOriginalAudience(modifiedAudience));
    // setting the current audience to the data from database
    yield call(loadCurrentAudienceAndUpdateEntities, modifiedAudience);
    // settign audience loading state to done
    yield put(setAudienceLoadingState({ state: LoadingStates.DONE }));
  } catch (e) {
    yield put(
      setUIState({
        value: true,
        uiPropertyName: 'errorFetching',
      })
    );
    // setting loading state to finished
    yield put(setAudienceLoadingState({ state: LoadingStates.ERROR }));
    // setting a notification for failure
    yield put(
      addNotification({
        state: LoadingStates.ERROR,
        message: `Failed to Retrieve Audience ${action.payload.name}`,
      })
    );
  }
}

function* watchUpdateAudience(action: PayloadAction<NormalizedObjectsProps>) {
  const payload = action.payload?.payload || action.payload;
  const { updatedAudience } = payload;
  try {
    yield put(setAudienceLoadingState({ state: LoadingStates.LOADING }));
    // setting the state of audiences to loading
    yield put(
      updateStates({ id: updatedAudience.id, state: LoadingStates.LOADING })
    );
    const result = yield call(handleUpdateAudience, payload);
    const modifiedAudience = sortAudienceDimensions(result.data);
    yield put(loadOriginalAudience(modifiedAudience));
    // remove from the loading notification
    yield put(removeStates({ id: updatedAudience.id }));
    // update the current audiences and entities
    yield call(loadCurrentAudienceAndUpdateEntities, modifiedAudience);
    // setting the loading state to done, the audience is ready to be viewed and played around with
    yield put(setAudienceLoadingState({ state: LoadingStates.DONE }));
    // adding a notification for success
    yield put(
      addNotification({
        state: LoadingStates.DONE,
        message: `Successfully Updated Audience ${updatedAudience.name}`,
      })
    );
    yield put(toggleDraftStateDialog({ open: false }));
  } catch (e) {
    // setting loading state to done
    yield put(setAudienceLoadingState({ state: LoadingStates.ERROR }));
    // setting loading state to finished
    yield put(
      addNotification({
        state: LoadingStates.ERROR,
        message: `Failed to Update Audience ${updatedAudience.name}`,
      })
    );
  }
}

function* watchDeleteAudience(action: PayloadAction<Audience>) {
  try {
    // updating the state so that the audience is no longer clickable
    yield put(
      updateStates({ id: action.payload.id, state: LoadingStates.LOADING })
    );
    // clearing the current audience after we update the states
    yield put(clearCurrentAudience());
    // deleting the audience
    yield call(handleDeleteAudience, action.payload);
    // remove the audience eand it's entities
    yield call(removeAudienceAndUpdateEntities, action.payload);
    // remove the audience from the states as it no longer exists
    yield put(removeStates({ id: action.payload.id }));
    // then add a notification for success
    yield put(
      addNotification({
        state: LoadingStates.DONE,
        message: `Successfully Deleted Audience ${action.payload.name}`,
      })
    );
  } catch (e) {
    // remove the audience from the states as it failed
    yield put(removeStates({ id: action.payload.id }));
    // set up a failure notification
    yield put(
      addNotification({
        state: LoadingStates.ERROR,
        message: `Failed to Delete Audience ${action.payload.name}`,
      })
    );
  }
}

function* watchDuplicateAudience(
  action: PayloadAction<DuplicateAudienceParams>
) {
  const { audience } = action.payload;
  try {
    // set audience loading to true so that we disable everything
    yield put(setAudienceLoadingState({ state: LoadingStates.LOADING }));
    // duplicate the audience
    const result = yield call(handleDuplicateAudience, action.payload);
    const newAudience = sortAudienceDimensions(result.data);
    const newAudienceId = newAudience.id;
    window.history.pushState(null, document.title, `${newAudienceId}`);
    yield put(loadOriginalAudience(newAudience));
    // load the payload of the result into the current audience
    yield call(loadCurrentAudienceAndUpdateEntities, newAudience);
    // set the audience loading to done
    yield put(setAudienceLoadingState({ state: LoadingStates.DONE }));
    yield put(toggleDraftStateDialog({ open: false }));
    // close the duplicate action dialogue
    const actionProps: ToggleDuplicateAudienceDialogAction = {
      open: false,
    };
    yield put(toggleDuplicateAudienceDialog(actionProps));
    // add a notification saying successfully created audience
    yield put(
      addNotification({
        state: LoadingStates.DONE,
        message: `Successfully Duplicated Audience ${audience.name}`,
      })
    );
  } catch (e) {
    // set audience loading state to error
    yield put(setAudienceLoadingState({ state: LoadingStates.ERROR }));
    // add notification saying it failed
    yield put(
      addNotification({
        state: LoadingStates.ERROR,
        message: `Failed to Duplicate Audience ${audience.name}`,
      })
    );
  }
}

export default function* watchAll() {
  yield takeLatest<any>(createAudience.type, watchCreateAudience);
  yield takeLatest<any>(fetchAudience.type, watchFetchAudience);
  yield takeLatest<any>(saveAudience.type, watchUpdateAudience);
  yield takeLatest<any>(searchAudiences.type, watchSearchAudiences);
  yield takeLatest<any>(deleteAudience.type, watchDeleteAudience);
  yield takeLatest<any>(duplicateAudience.type, watchDuplicateAudience);
  yield takeLatest<any>(querySurveys.type, watchQuerySurvey);
}
