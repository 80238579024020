import {
  Check,
  CircleHalf,
  CircleHalfTilt,
  WarningCircle,
} from '@phosphor-icons/react';
import { theme } from '@klover/attain-design-system';
import * as Styled from './index.styles';

export enum SectionStatusValue {
  COMPLETED = 'COMPLETED',
  ERROR = 'ERROR',
  IN_PROGRESS = 'IN_PROGRESS',
}

interface Props {
  status: SectionStatusValue;
  isModeling: boolean;
}

export const ModelingStatus = ({ status, isModeling }: Props) => {
  const originalStatus = isModeling ? SectionStatusValue.IN_PROGRESS : status;
  switch (originalStatus) {
    case SectionStatusValue.COMPLETED:
      return (
        <Styled.StatusComplete>
          <Check size={theme.icons.lg} />
          Model Complete
        </Styled.StatusComplete>
      );
    case SectionStatusValue.ERROR:
      return (
        <Styled.StatusErrorsDetected>
          <WarningCircle size={theme.icons.lg} />
          Model Error
        </Styled.StatusErrorsDetected>
      );
    case SectionStatusValue.IN_PROGRESS:
      return (
        <Styled.StatusInProgress>
          <CircleHalf size={theme.icons.lg} />
          Model Pending
        </Styled.StatusInProgress>
      );
    default:
      return (
        <Styled.StatusNotStarted>
          <CircleHalfTilt size={theme.icons.lg} />
          Not Modeled
        </Styled.StatusNotStarted>
      );
  }
};

export default ModelingStatus;
