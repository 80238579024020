import styled from 'styled-components';
import { Autocomplete } from '@klover/attain-design-system';

export const SelectAutoCompleteGroup = styled(Autocomplete)`
  width: 100%;
  display: inline-flex;
  & input {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    font-family: Calibre-Regular, sans-serif;
  }
`;

export const SelectAutoCompleteGroupOptions = styled.li`
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
  word-break: break-all;
`;

export const DividerLabel = styled.div`
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;
