import axios from 'axios';
import {
  AUDIENCE_EXPORT_TYPES,
  MAX_SEGMENT_NAME_LENGTH,
  OPENX_MAX_SEGMENT_NAME_LENGTH,
  TTD_DATA_ALLIANCE_MAX_SEGMENT_NAME_LENGTH,
  ValidationErrorMessages,
  doesNotUseIs,
} from '../constants';
import { CSV_FILE_FORMAT_ERROR, VALID_FILE } from '../content';
import { auth } from 'utils/firebase';

/**
 * Get the epoch time (milliseconds since January 1, 1970, 00:00:00 UTC) for a given date.
 * @param {Date | string | number} date The date for which to calculate the epoch time.
 * @param {number} [hours=0] The hours component of the time (0-23).
 * @param {number} [minutes=0] The minutes component of the time (0-59).
 * @param {number} [seconds=0] The seconds component of the time (0-59).
 * @param {number} [milliseconds=0] The milliseconds component of the time (0-999).
 * @returns {number} The epoch time in milliseconds.
 */
export const getEpochTime = (
  date: Date | string | number,
  hours = 0,
  minutes = 0,
  seconds = 0,
  milliseconds = 0
): number => {
  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  // Set hours, minutes, seconds, and milliseconds to provided values
  date.setUTCHours(hours, minutes, seconds, milliseconds);

  // Calculate epoch time
  return date.getTime();
};

export const isFloat = (input: string) => {
  if (input === '') return false;
  // Regular expression to match float numbers
  const floatRegex = /^-?\d*(\.\d+)?$/;
  // Check if the input matches the float regex
  return floatRegex.test(input);
};

export const validateCustomAudienceCsv = (
  file: File,
  setValidationDetails: any
) => {
  const reader = new window.FileReader();

  reader.onload = (e) => {
    const contents = e?.target?.result;
    const lines = contents?.split('\n');

    // Check if CSV has only one column and all values are alphanumeric
    const isValid = lines.every((line) => {
      const values = line.trim().split(',');
      if (
        values.length === 1 &&
        ((values[0].match(/^[a-zA-Z0-9]+$/) && values[0].length === 40) ||
          values[0] === '')
      ) {
        return true;
      }
      return false;
    });

    if (isValid) {
      setValidationDetails({
        valid: true,
        message: VALID_FILE,
      });
    } else {
      setValidationDetails({
        valid: false,
        message: CSV_FILE_FORMAT_ERROR,
      });
    }
  };

  reader.readAsText(file);
};

/**
 * Retrieves audience emails.
 *
 * @param {boolean} isCustomAudience - Indicates if the audience is custom audience.
 * @returns {Promise<AxiosResponse>} A promise that resolves to the Axios response containing the audience emails.
 */
export const getAudeinceEmails = (isCustomAudience: boolean) => {
  const url = isCustomAudience
    ? `${process.env.API_BASE_ADDRESS}/custom-audience-manager/emails`
    : `${process.env.API_BASE_ADDRESS}/audience-manager/emails`;
  return auth?.currentUser?.getIdTokenResult().then((res) => {
    const authorizedAxiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${res.token}`,
      },
    });
    return authorizedAxiosInstance.get(url);
  });
};

/**
 * Validates the segment name based on the selected export type.
 *
 * @param {string} selectedExportType - The type of export selected (e.g., 'custom_segment', 'ots_segment').
 * @param {string} segmentName - The name of the segment to be validated.
 * @returns {{ isValid: boolean, validationMessage: string }} - An object indicating whether the segment name is valid and a corresponding validation message.
 */
export const isSegmentNameValid = (
  selectedExportType: Array<string> = [],
  segmentName: string
) => {
  const returnObject = {
    isValid: true,
    validationMessage: '',
  };

  // Regular expression to detect forbidden characters (double quotes, single quote, caret)
  const forbiddenCharsPattern = /['"^]/;

  if (
    selectedExportType.includes(AUDIENCE_EXPORT_TYPES.CUSTOM_SEGMENT) ||
    selectedExportType.includes(AUDIENCE_EXPORT_TYPES.OTS_SEGMENT)
  ) {
    // Check for forbidden characters
    if (forbiddenCharsPattern.test(segmentName)) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.FORBIDDEN_CHARACTER_ERROR;
      return returnObject;
    }

    if (segmentName && !segmentName.startsWith('Attain')) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.SEGMENT_NAME_START_CHAR_ERROR;
      return returnObject;
    }

    // Check for consecutive '>' symbols
    if (segmentName.replace(/\s/g, '').includes('>>')) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.CONSECUTIVE_GREATER_THAN_SYMBOL_ERROR;
      return returnObject;
    }

    // Split the segment name by '>' and check the length of each part
    const segments = segmentName.split('>');

    for (const segment of segments) {
      if (segment.trim().length > MAX_SEGMENT_NAME_LENGTH) {
        returnObject.isValid = false;
        returnObject.validationMessage =
          ValidationErrorMessages.SEGMENT_SECTION_TOO_LONG_ERROR;
        return returnObject;
      }
    }
  }

  if (
    selectedExportType.includes(AUDIENCE_EXPORT_TYPES.PUBMATIC_OTS_SEGMENT) ||
    selectedExportType.includes(AUDIENCE_EXPORT_TYPES.PUBMATIC_CUSTOM_SEGMENT)
  ) {
    if (segmentName.trim().length > MAX_SEGMENT_NAME_LENGTH) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.SEGMENT_SECTION_SHOULD_NOT_EXCEED_ERROR.replace(
          '${MAX_SEGMENT_NAME_LENGTH}',
          MAX_SEGMENT_NAME_LENGTH.toString()
        );
      return returnObject;
    }
  }

  if (
    selectedExportType.includes(AUDIENCE_EXPORT_TYPES.OPENX_OTS_SEGMENT) ||
    selectedExportType.includes(AUDIENCE_EXPORT_TYPES.OPENX_CUSTOM_SEGMENT)
  ) {
    if (segmentName.trim().length > OPENX_MAX_SEGMENT_NAME_LENGTH) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.SEGMENT_SECTION_SHOULD_NOT_EXCEED_ERROR.replace(
          '${MAX_SEGMENT_NAME_LENGTH}',
          OPENX_MAX_SEGMENT_NAME_LENGTH.toString()
        );
      return returnObject;
    }
  }

  if (selectedExportType.includes(AUDIENCE_EXPORT_TYPES.TTD_DATA_ALLIANCE)) {
    // Check for forbidden characters
    if (forbiddenCharsPattern.test(segmentName)) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.FORBIDDEN_CHARACTER_ERROR;
      return returnObject;
    }

    if (segmentName && !segmentName.startsWith('Data Alliance')) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.TTD_DATA_ALLIANCE_SEGMENT_NAME_START_CHAR_ERROR;
      return returnObject;
    }

    // Check for consecutive '>' symbols
    if (segmentName.replace(/\s/g, '').includes('>>')) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.CONSECUTIVE_GREATER_THAN_SYMBOL_ERROR;
      return returnObject;
    }

    if (segmentName.trim().length > TTD_DATA_ALLIANCE_MAX_SEGMENT_NAME_LENGTH) {
      returnObject.isValid = false;
      returnObject.validationMessage =
        ValidationErrorMessages.SEGMENT_SECTION_SHOULD_NOT_EXCEED_ERROR.replace(
          '${MAX_SEGMENT_NAME_LENGTH}',
          TTD_DATA_ALLIANCE_MAX_SEGMENT_NAME_LENGTH.toString()
        );
      return returnObject;
    }
  }
  return returnObject;
};

/**
 * Returns the default operator for a given property name.
 *
 * Some property names use the '=' operator by default, while others use 'is'.
 * This function checks if the property name is in the list that uses '=' and
 * returns the appropriate default operator.
 *
 * @param {string} propertyName - The name of the property to check.
 * @returns {string} - The default operator for the given property ('=' or 'is').
 */
export const getDefaultOperator = (propertyName: string): string => {
  const defaultOperator = doesNotUseIs[propertyName] ? '=' : 'is';
  return defaultOperator;
};

export const sortTableData = (data: any, sortBy: string, sortOrder: string) => {
  return data.sort((a, b) => {
    let fieldA = a[sortBy];
    let fieldB = b[sortBy];
    const isNumber = !isNaN(Number(fieldA));
    // If the field is a string, compare them lexicographically
    if (!isNumber) {
      fieldA = fieldA?.toLowerCase();
      fieldB = fieldB?.toLowerCase();
    }

    // If sortOrder is ascending (ASC)
    if (sortOrder === 'ASC') {
      if (fieldA > fieldB) return 1;
      if (fieldA < fieldB) return -1;
      return 0;
    }
    // If sortOrder is descending (DESC)
    else if (sortOrder === 'DESC') {
      if (fieldA > fieldB) return -1;
      if (fieldA < fieldB) return 1;
      return 0;
    }

    return 0; // In case sortOrder is neither ASC nor DESC
  });
};

export const groupModeledAudienceCountByIdType = (data: any) => {
  if (!Array.isArray(data)) {
    return {};
  }

  const sortedData = [...data].sort((a, b) => a.idType.localeCompare(b.idType));

  return sortedData.reduce((acc: any, item: any) => {
    if (!acc[item.idType]) {
      acc[item.idType] = [];
    }
    acc[item.idType].push(item);
    return acc;
  }, {});
};

export const calculateMinAndMaxAudienceCount = (groupedData: any) => {
  let minAudienceCount = 0;
  let maxAudienceCount = 0;
  Object.values(groupedData).forEach((items) => {
    if (items[0]) {
      minAudienceCount += items[0].audienceCount;
    }
    if (items[10]) {
      maxAudienceCount += items[10].audienceCount;
    }
  });

  return {
    minimumAudienceCount: minAudienceCount.toLocaleString(),
    maximumAudienceCount: maxAudienceCount.toLocaleString(),
  };
};

/**
 * Check desciption props & validation
 */
export const isDescriptionValid = (
  selectedExportType: Array<string> = [],
  description: string
) => {
  const result = {
    isValid: true,
    isRequired: false,
    validationMessage: '',
  };
  if (
    selectedExportType.includes(AUDIENCE_EXPORT_TYPES.PUBMATIC_OTS_SEGMENT) ||
    selectedExportType.includes(AUDIENCE_EXPORT_TYPES.PUBMATIC_CUSTOM_SEGMENT)
  ) {
    if (description.trim().length > MAX_SEGMENT_NAME_LENGTH) {
      result.isValid = false;
      result.validationMessage =
        ValidationErrorMessages.DESCRIPTION_SECTION_TOO_LONG_ERROR;
    }

    result.isRequired = true;
    return result;
  }

  return result;
};
