import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useAppDispatch, useAppSelector } from 'redux/hooks';

import {
  AmountOptionKey,
  AmountOptionKeys,
  CountOptionKey,
  CountOptionKeys,
  FilterOperatorKey,
  FilterOperatorKeys,
  TimeframeOperatorKey,
  TimeframeOperatorKeys,
  TimeframeOptionKey,
  TimeframeOptionKeys,
} from '../interfaces';

import DateFnsUtils from '@date-io/date-fns';
import {
  Grid,
  IconButton,
  MenuItem,
  Select,
} from '@klover/attain-design-system';
import {
  query,
  updateAmount,
  updateAmountCustom,
  updateAmountOperator,
  updateCount,
  updateCountCustom,
  updateCountOperator,
  updateTimeframe,
  updateTimeframeEnd,
  updateTimeframeOperator,
  updateTimeframeStart,
} from '../slice';
import 'date-fns';

// Material UI Components
import Box from '@material-ui/core/Box';
import CloseIcon from '@material-ui/icons/Close';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import tokens from 'tokens';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import {
  Theme,
  ThemeProvider,
  createStyles,
  createTheme,
  makeStyles,
} from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    customCancelButton: {
      marginTop: theme.spacing(1),
      width: 48,
    },
    customControl: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 100,
    },
    filterPaper: {
      margin: theme.spacing(1),
      borderRadius: 10,
    },
    filterControl: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      minWidth: 140,
    },
    subtitle: {
      fontFamily: 'Calibre-Regular,sans-serif',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 16,
      color: tokens.colorTextMuted,
    },
    timeframe: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxHeight: 48,
      minWidth: 120,
    },
    chips: {
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: 18,
      color: tokens.colorTextSecondary,
      fontFamily: 'Calibre-Regular,sans-serif',
    },
  })
);

const chip = createTheme({
  overrides: {
    MuiMenu: {
      list: {
        padding: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: tokens.colorTextMuted,

        '&$focused': {
          color: tokens.colorTextPrimary,
        },
      },
    },
    MuiInputBase: {
      root: {
        maxHeight: 48,
      },
      input: {
        borderRadius: 10,
        alignSelf: 'center',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 10,
      },
    },
    MuiSelect: {
      root: {
        padding: 14,
        '&$focused': {
          border: '1px solid ' + tokens.colorPrimary,
        },
      },
    },
  },
});

const TransactionFilters = () => {
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const dimension = useAppSelector(
    (state) => state.audienceReducer.currentDimension
  );

  useEffect(() => {
    if (!dimension?.timeframeOperator && dimension?.conditions?.length === 1)
      dispatch(updateTimeframeOperator('inTheLast'));
    if (!dimension?.timeframe && dimension?.conditions?.length === 1)
      dispatch(updateTimeframe('30 days'));
  }, []);

  const timeframes: TimeframeOptionKey[] = useAppSelector((state) => {
    const dimension = state.audienceReducer.currentDimension;
    if (dimension) {
      switch (dimension.category) {
        case 'product':
        case 'transactions':
          return TimeframeOptionKeys.filter((e) => e !== '');
        default:
          break;
      }
    }

    return [];
  });

  const timeframeOperators: string[] = TimeframeOperatorKeys;

  const amounts: AmountOptionKey[] = useAppSelector((state) => {
    const dimension = state.audienceReducer.currentDimension;
    if (dimension) {
      switch (dimension.category) {
        case 'product':
        case 'transactions':
          return AmountOptionKeys.filter((e) => e !== '');
        default:
          break;
      }
    }

    return [];
  });

  const amountOperators: FilterOperatorKey[] = FilterOperatorKeys;

  const counts: CountOptionKey[] = useAppSelector((state) => {
    const dimension = state.audienceReducer.currentDimension;
    if (dimension) {
      switch (dimension.category) {
        case 'product':
        case 'transactions':
          return CountOptionKeys.filter((e) => e !== '');
        default:
          break;
      }
    }

    return [];
  });

  const countOperators: FilterOperatorKey[] = FilterOperatorKeys;

  const handleTimeframeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const timeframe = event.target.value as TimeframeOptionKey;
    if (dimension) {
      dispatch(updateTimeframe(timeframe));
      dispatch(query());
    }
  };

  const handleTimeframeEndChange = (date: Date | null) => {
    if (date) {
      const timestamp = +date;
      dispatch(updateTimeframeEnd(timestamp));
      dispatch(query());
    }
  };

  const handleTimeframeOperatorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const timeframeOperator = event.target.value as TimeframeOperatorKey;
    if (dimension) {
      dispatch(updateTimeframeOperator(timeframeOperator));
      dispatch(query());
    }
  };

  const handleTimeframeStartChange = (date: Date | null) => {
    if (date) {
      const timestamp = +date;
      dispatch(updateTimeframeStart(timestamp));
      dispatch(query());
    }
  };

  const handleAmountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const amount = event.target.value as AmountOptionKey;
    if (dimension) {
      dispatch(updateAmount(amount));
      dispatch(query());
    }
  };

  const handleAmountCustomChange = (customStr: string) => {
    if (dimension && !isNaN(customStr)) {
      const customVal: number = +customStr;
      dispatch(updateAmountCustom(customVal));
    }
  };

  const handleAmountOperatorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const amountOperator = event.target.value as FilterOperatorKey;
    if (dimension) {
      dispatch(updateAmountOperator(amountOperator));
      dispatch(query());
    }
  };

  const handleBlur = () => {
    if (dimension) {
      dispatch(query());
    }
  };

  const handleCancelAmountCustom = () => {
    if (dimension) {
      dispatch(updateAmount(''));
      dispatch(updateAmountOperator(''));
      dispatch(updateAmountCustom(undefined));
      dispatch(query());
    }
  };

  const handleCancelCountCustom = () => {
    if (dimension) {
      dispatch(updateCount(''));
      dispatch(updateCountOperator(''));
      dispatch(updateCountCustom(undefined));
      dispatch(query());
    }
  };

  const handleCancelTimeframeCustom = () => {
    if (dimension) {
      dispatch(updateTimeframe(''));
      dispatch(updateTimeframeEnd(undefined));
      dispatch(updateTimeframeOperator(''));
      dispatch(updateTimeframeStart(undefined));
      dispatch(query());
    }
  };

  const handleCountChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const count = event.target.value as CountOptionKey;

    if (dimension) {
      dispatch(updateCount(count));
      dispatch(query());
    }
  };

  const handleCountCustomChange = (customStr: string) => {
    if (dimension && !isNaN(customStr)) {
      const customVal: number = +customStr;
      dispatch(updateCountCustom(customVal));
    }
  };

  const handleCountOperatorChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    const countOperator = event.target.value as FilterOperatorKey;
    if (dimension) {
      dispatch(updateCountOperator(countOperator));
      dispatch(query());
    }
  };

  const isAmountCustom = dimension?.amount === 'custom';
  const isCountCustom = dimension?.count === 'custom';
  const isTimeframeCustom = dimension?.timeframeOperator === 'custom';

  return (
    <>
      {/* Filter Divider */}
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Box marginLeft={5} marginTop={5}>
            <Typography className={classes.subtitle}>
              Transaction Filters
            </Typography>
          </Box>

          <Divider />
        </Grid>
      </Grid>

      <Box style={{ margin: '10px 20px' }}>
        {/* Filters */}
        <Grid container spacing={0}>
          <ThemeProvider theme={chip}>
            <Grid item xs={4}>
              <Paper className={classes.filterPaper} variant="outlined">
                <Box p={2}>
                  <Typography className={classes.chips}>
                    Purchase timeframe
                  </Typography>

                  <Box style={{ display: 'flex' }}>
                    {isTimeframeCustom ? (
                      <>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <DatePicker
                            id="date-picker-start"
                            maxDate={dimension?.timeframeEnd}
                            className={classes.timeframe}
                            label="Start"
                            format="MM/dd/yyyy"
                            value={dimension?.timeframeStart}
                            onChange={handleTimeframeStartChange}
                          />
                          <DatePicker
                            id="date-picker-end"
                            minDate={dimension?.timeframeStart}
                            maxDate={Date.now()}
                            className={classes.timeframe}
                            label="End"
                            format="MM/dd/yyyy"
                            value={dimension?.timeframeEnd}
                            onChange={handleTimeframeEndChange}
                          />
                        </MuiPickersUtilsProvider>
                        <IconButton
                          className={classes.customCancelButton}
                          onClick={handleCancelTimeframeCustom}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <FormControl
                          variant="outlined"
                          className={classes.filterControl}
                        >
                          <Select
                            labelId="timeframe-operator-label"
                            id="timeframe-operator-select"
                            variant="outlined"
                            value={dimension?.timeframeOperator}
                            onChange={handleTimeframeOperatorChange}
                          >
                            {timeframeOperators.map((operator) => {
                              const menuText =
                                operator === ''
                                  ? 'None'
                                  : _.lowerCase(operator);
                              return (
                                <MenuItem key={operator} value={operator}>
                                  {menuText}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>

                        <FormControl
                          variant="outlined"
                          className={classes.filterControl}
                        >
                          <Select
                            labelId="timeframe-label"
                            id="timeframe-select"
                            variant="outlined"
                            value={dimension?.timeframe}
                            onChange={handleTimeframeChange}
                          >
                            {timeframes.map((timeframe) => {
                              return (
                                <MenuItem key={timeframe} value={timeframe}>
                                  {timeframe}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.filterPaper} variant="outlined">
                <Box p={2}>
                  <Typography className={classes.chips}>
                    Transaction count per user
                  </Typography>

                  <Box style={{ display: 'flex' }}>
                    <FormControl
                      variant="outlined"
                      className={classes.filterControl}
                    >
                      <Select
                        labelId="count-operator-label"
                        id="count-operator-select"
                        defaultValue=""
                        variant="outlined"
                        value={dimension?.countOperator}
                        onChange={handleCountOperatorChange}
                      >
                        {countOperators.map((operator) => {
                          const menuText =
                            operator === '' ? 'None' : _.lowerCase(operator);
                          return (
                            <MenuItem key={operator} value={operator}>
                              {menuText}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    {isCountCustom ? (
                      <>
                        <FormControl
                          color="primary"
                          variant="outlined"
                          className={classes.customControl}
                        >
                          <TextField
                            onBlur={handleBlur}
                            onChange={(e) =>
                              handleCountCustomChange(e.target.value)
                            }
                            value={dimension?.countCustom}
                            variant="outlined"
                          />
                        </FormControl>

                        <IconButton
                          className={classes.customCancelButton}
                          onClick={handleCancelCountCustom}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <FormControl
                        variant="outlined"
                        className={classes.filterControl}
                      >
                        <Select
                          labelId="transaction-count-label"
                          id="transaction-count-select"
                          defaultValue=""
                          variant="outlined"
                          value={dimension?.count}
                          onChange={handleCountChange}
                        >
                          {counts.map((count) => {
                            return (
                              <MenuItem key={count} value={count}>
                                {count.replace(/\w+/g, _.capitalize)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={4}>
              <Paper className={classes.filterPaper} variant="outlined">
                <Box p={2}>
                  <Typography className={classes.chips}>
                    Spend per user
                  </Typography>

                  <Box style={{ display: 'flex' }}>
                    <FormControl
                      variant="outlined"
                      className={classes.filterControl}
                    >
                      <Select
                        labelId="amount-operator-label"
                        id="amount-operator-select"
                        defaultValue=""
                        variant="outlined"
                        value={dimension?.amountOperator}
                        onChange={handleAmountOperatorChange}
                      >
                        {amountOperators.map((operator) => {
                          const menuText =
                            operator === '' ? 'None' : _.lowerCase(operator);
                          return (
                            <MenuItem key={operator} value={operator}>
                              {menuText}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>

                    {isAmountCustom ? (
                      <>
                        <FormControl
                          color="primary"
                          variant="outlined"
                          className={classes.customControl}
                        >
                          <TextField
                            onBlur={handleBlur}
                            onChange={(e) =>
                              handleAmountCustomChange(e.target.value)
                            }
                            value={dimension?.amountCustom}
                            variant="outlined"
                          />
                        </FormControl>

                        <IconButton
                          className={classes.customCancelButton}
                          onClick={handleCancelAmountCustom}
                          size="small"
                        >
                          <CloseIcon />
                        </IconButton>
                      </>
                    ) : (
                      <FormControl
                        variant="outlined"
                        className={classes.filterControl}
                      >
                        <Select
                          labelId="transaction-amount-label"
                          id="transaction-amount-select"
                          defaultValue=""
                          variant="outlined"
                          value={dimension?.amount}
                          onChange={handleAmountChange}
                        >
                          {amounts.map((amount) => {
                            return (
                              <MenuItem key={amount} value={amount}>
                                {amount.replace(/\w+/g, _.capitalize)}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    )}
                  </Box>
                </Box>
              </Paper>
            </Grid>
          </ThemeProvider>
        </Grid>
      </Box>
    </>
  );
};

export default TransactionFilters;
