import { StringToBoolMap } from 'interfaces';
import { Pagination } from 'interfaces/notifications';

export enum UploadStates {
  DONE = 'DONE',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
}
export enum NotificationStates {
  DONE = 'DONE',
  ERROR = 'ERROR',
}

export enum FsCollections {
  NOTIFICATIONS = 'notifications',
  TOASTERS = 'toasters',
}

export enum LoadingStates {
  IDLE = 'idle',
  LOADING = 'loading',
  DONE = 'done',
  FAILED = 'failed',
  ERROR = 'error',
}

export enum KDSPageNames {
  AUDIENCEMANAGER = 'audience-manager',
  OFFERWALLMANAGER = 'offer-wall-manager',
  SALESLIFT = 'sales-lift-studies',
  QUICKQUESTIONS = 'quick-questions-manager',
  NOTIFICATIONS = 'notifications',
  CUSTOMAUDIENCES = 'custom-audience-manager',
}

export enum ValidationErrorMessages {
  PAYOUT = 'Payout value must be zero or a positive number',
  POINTS = 'Klover Points value must be zero or a positive number',
  CONVERSION_CAPPING = 'Conversion Capping value must be zero or a positive number',
  TUNE_GOALS_POINTS_AWARDED = 'Klover Points Award for Tune Goal must be zero or a positive number',
  TUNE_GOALS_ID = 'Tune Goal ID for Tune Goal must be zero or a positive number',
  TUNE_GOALS_ID_REQUIRED = 'Tune Goal ID for Tune Goal is required',
  TUNE_GOALS_CONVERSION_VALUE = 'Cap Limit for Tune Goal must be zero or a positive number',
  NON_NEGATIVE_NUMBER = 'Value must be zero or a positive number',
  FUTURE_START_DATE_ACTIVE_OFFER = 'Future start date not allowed for active offer',
  START_DATE_SHOULD_BE_IN_FUTURE = 'Date should be in future for inactive offer',
  END_DATE_EARLIER_THAN_START_DATE = 'End Date should not be before start date',
  CURRENT_DATE_NOT_IN_RANGE = 'Ensure current date falls within flight date range',
  CONSECUTIVE_GREATER_THAN_SYMBOL_ERROR = "Invalid segment name: Consecutive '>>' symbols are not permitted.",
  FORBIDDEN_CHARACTER_ERROR = `Do not include special characters such as ', ", or ^`,
  SEGMENT_SECTION_TOO_LONG_ERROR = 'Segment Child name should not exceed 256 characters',
  SEGMENT_SECTION_SHOULD_NOT_EXCEED_ERROR = 'Segment name should not exceed ${MAX_SEGMENT_NAME_LENGTH} characters',
  SEGMENT_NAME_START_CHAR_ERROR = `Segment name must start with "Attain"`,
  TTD_DATA_ALLIANCE_SEGMENT_NAME_START_CHAR_ERROR = `Segment name must start with "Data Alliance"`,
  DESCRIPTION_SECTION_TOO_LONG_ERROR = 'Description should not exceed 256 characters',
}

export const DefaultPaginationValues: Pagination = { page: 1, limit: 15 };

export enum ExportAudience {
  KLOVER_IDS = 'Klover IDs',
  KLOVER_RAMP_IDS = 'Klover + Ramp IDs',
  TRANSUNION = 'TransUnion',
  TRANSUNION_TTD = 'TransUnion + TTD',
  TRANSUNION_HEMS = 'TransUnion HEMs Only',
  CUSTOM_SEGMENT = 'Trade Desk Custom Segment',
  OTS_SEGMENT = 'LiveRamp + Trade Desk OTS Segment',
  LIVERAMP_CUSTOM_SEGMENT = 'LiveRamp Custom Segment',
  TTD_DATA_ALLIANCE = 'Trade Desk Data Alliance',
}

export const CUSTOM_SEGMENT_INFORMATION = [
  'KDS audience will include email, phone, first, last name, and address',
  'Sends this audience to TransUnion for lookalike modeling',
  'You need to login to TU to adjust modeling settings',
  'Once TransUnion is finished building the lookalike model, a new custom segment will be created in The Trade Desk.',
];

export const OTS_SEGMENT_INFORMATION = [
  'KDS audience will include HEMs only',
  'Sends this audience to TransUnion for lookalike modeling',
  'TransUnion will automatically create lookalike models and send them to LiveRamp and to our platform',
  'Our platform will create a new OTS segment in The Trade Desk.',
];

export const TTD_DATA_ALLIANCE_SEGMENT_INFORMATION = [
  'To be determined...',
  'To be determined...',
];

export const EXPORT_EMAIL_INFO_TEXT =
  'Supply an email address to send a CSV file with the audience information you created.';
export const SELECT_EXPORT_TYPE = 'Select export type';
export const REQUIRED_FIELDS_ARE_MISSING = 'Required fields are missing';

export enum AUDIENCE_EXPORT_TYPES {
  RAMP_ID = 'rampID',
  CUSTOM_SEGMENT = 'custom_segment',
  OTS_SEGMENT = 'ots_segment',
  KLOVER_IDS = 'klover_ids',
  TRANSUNION = 'transunion',
  TRANSUNION_TTD = 'transunion_ttd',
  TRANSUNION_HEMS = 'transunion_hems',
  LIVERAMP_CUSTOM_SEGMENT = 'liveramp_custom_segment',
  PUBMATIC_OTS_SEGMENT = 'pubmatic_ots_segment',
  PUBMATIC_CUSTOM_SEGMENT = 'pubmatic_custom_segment',
  OPENX_OTS_SEGMENT = 'openx_ots_segment',
  OPENX_CUSTOM_SEGMENT = 'openx_custom_segment',
  TTD_DATA_ALLIANCE = 'ttd_data_aliance',
}

export const AUDIENCE_EXPORT_FIELD_TITLES = {
  ots_segment: 'The Tread Desk',
  custom_segment: 'The Tread Desk Custom',
  liveramp_custom_segment: 'Liveramp',
};

export enum LIVERAMP_SEGMENT_FIELD_NAME {
  PROGRAMMATIC_PERCENT_OF_MEDIA = 'Programmatic % of media',
  CPM = 'CPM',
  DESCRIPTION = 'Description',
  CUSTOM_SEGMENT_NAME = 'Custom Segment Name',
  TV_TARGETING = 'TV Targeting CPM',
  ADVERTISER_DIRECT = 'Advertiser Direct CPM',
  CHOOSE_INTEGRATION = 'Choose Integration (Destination)',
  CHOOSE_ADVERTISER = 'Choose Advertiser (Distribution Manager)',
}

export enum QUESTION_FIELD_MAPPING {
  audience_id = 'Audience ID',
  answer_type = 'Answer Type',
  answers = 'Answers',
  points = 'Points',
  question_text = 'Question Text',
  status = 'Status',
  audience_type = 'Audience Type',
}

export enum AUDIENCE_MANAGER_FIELD_MAPPING {
  audience_name = 'Audience Name',
  dimension_groups = 'Dimension Groups',
  dimensions = 'Dimensions',
  size = 'Size',
}

export enum SYSTEM_NAME {
  offer_wall_manager = 'offer_wall_manager',
  audience_manager = 'audience_manager',
  quick_questions_manager = 'quick_questions_manager',
}

export enum OFFER_FIELD_MAPPING {
  advertiser_gid = 'Advertiser GID',
  background_image_url = 'Background Image URL',
  body = 'Body',
  call_to_action = 'Call to Action',
  clickthrough_url = 'Clickthrough URL',
  comparison_emoji = 'Comparison Emoji',
  comparison_text = 'Comparison Text',
  disclosure_body = 'Disclosure Body',
  disclosure_header = 'Disclosure Header',
  flight_end_date = 'Flight End Date',
  flight_start_date = 'Flight Start Date',
  image_url = 'Image URL',
  impression_event = 'Impression Event',
  is_disclosure = 'Is Disclosure',
  klover_points_conversion_event = 'Klover Points Conversion Event',
  multiple_conversion_event_override = 'Multiple Conversion Event Override',
  offer_label = 'Label',
  offer_name = 'Offer Name',
  offer_status = 'Status',
  payout = 'Payout',
  placement = 'Placement',
  points = 'Points',
  price = 'Price',
  pricing_model = 'Pricing Model',
  revenue_conversion_event = 'Revenue Conversion Event',
  subline = 'Subline',
  successurl = 'Success Urls',
  targetting_devices = 'Targeting Devices',
  targeting_audience_id = 'Targeting Audience ID',
  targeting_audience_name = 'Targeting Audience Name',
  targeting_states = 'Targeting States',
  targeting_zip_codes = 'Targeting Zip Codes',
  tune_goals = 'Tune Goals',
  z_index = 'Z Index',
}

export const CHANGE_LOG_ARRAY_FIELDS = [
  'successurl',
  'tune_goals',
  'targeting_states',
  'targeting_zip_codes',
  'answers',
  'dimension_groups',
  'impression_event',
  'dimensions',
];

export const CHANGE_LOG_BOOLEAN_FIELDS = [
  'multiple_conversion_event_override',
  'is_disclosure',
];

export const CHANGE_LOG_TIMESTAMP_FIELDS = [
  'flight_start_date',
  'flight_end_date',
];

export const ACTIVE_AUDIENCE = 'Active Audience';
export const SELECT_YOUR_DESTINATION = 'Select your destinations';
export const ADJUST_AND_MONITOR_AUDIENCE = 'Adjust & monitor your audience';

export const TRADE_DESK_SEGMENT_DETAILS = 'The Trade Desk Segment Details';
export const LIVERAMP_SEGMENT_DETAILS = 'LiveRamp Segment Details';
export const AUDIENCE_STATUS_HEADER_TITLE = 'Status';
export const AUDIENCE_SELECT_EMAIL_AUTO_COMPLETE = 'Select email';
export const EXPORT_STATUS_HEADER_TITLE = 'What does this mean?';
export const TABLE_SORT_TOOLTIP_TEXT = 'Toggle SortBy';
export const MAX_SEGMENT_NAME_LENGTH = 256;
export const OPENX_MAX_SEGMENT_NAME_LENGTH = 100;
export const TTD_DATA_ALLIANCE_MAX_SEGMENT_NAME_LENGTH = 1000;
export const AUDIENCE_EST_SIZE_TOOLTIP_HEADER = 'Audience Size History';
export const LIVERAMP_SEGMENT_PRICING_DETAILS = 'Pricing Details';
export const LIVERAMP_SEGMENT_ADVERTISER_SELECTION = 'Advertiser Selection';
export const REFRESH_AUDIENCE_DISABLE_MESSAGE =
  'Save audience before refreshing';
export const REFRESH_AUDIENCE = 'Get Latest Audience Size';
export const ATTAIN = 'attain';
export const COMPLETED = 'COMPLETED';

export const doesNotUseIs: StringToBoolMap = {
  balance: true,
  age: true,
  numberOfKids: true,
  numberInHousehold: true,
};

export const AudienceEstSizeLabel = {
  EXPORTED_AUDIENCE: 'Exported Audience',
  SEED_AUDIENCE: 'Seed Audience',
  TU_MODEL: 'TU Model',
  TTD_UUID2: 'TTD UID2s',
  TTD_AUDIENCE: 'TTD Audience',
};

export const AudienceSizeHistoryLabel = {
  SEED_AUDIENCE: 'Seed Audience',
  EXPORTED_AUDIENCE: 'Exported Audience',
  TU_MODEL: 'TU Model',
  UUID: 'UUID',
  TREAD_DESK: 'Trade Desk',
  ACTIVE_IDS: 'Active IDs',
  RECEIVED_IDS: 'Received IDs',
};

export const AudienceTypeLabel = {
  HEM_SHA256: 'Emails',
  IP: 'IP Addresses',
};
