import styled from 'styled-components';
import tokens from 'tokens';

import { Form as FormikForm } from 'formik';

export const Form = styled(FormikForm)`
  border-left: 1px solid ${tokens.colorGray40};
  display: inline-block;
  margin-left: ${tokens.spacing.md};
  padding-left: ${tokens.spacing.md};
`;

export const ModeledSize = styled.div`
  text-align: left;
  font-size: 16px;
  font-family: Calibre-Regular, sans-serif;
`;

export const Type = styled.div`
  font-size: 16px;
  display: flex;
  flex-direction: column;
  text-align: start;
`;

export const AudienceStatus = styled.div`
  display: flex;
  font-size: 16px;
`;
