import React, { useMemo, useState } from 'react';
import {
  EXPORT_HISTORY,
  EXPORT_HISTORY_HEADERS,
  NO_RECORDS_FOUND,
} from '../../../content';
// Material UI Components
import InfoIcon from '@mui/icons-material/InfoOutlined';
import Typography from '@material-ui/core/Typography';
import {
  AudienceEstSizePopup,
  AudienceExportStatus,
  getAudienceEstSizeDetails,
} from '..';
import {
  Popover,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  getAudienceAndPlatformTypeStatus,
  modifyProps,
} from '../exportHistoryDialog/exportHistoryUtils';
import * as Styled from './index.styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      margin: theme.spacing(3),
    },
    exportTitle: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontWeight: 600,
      fontSize: 22,
      lineHeight: '32px',
      minWidth: 1000,
      margin: '1rem 0 0 1.5rem',
    },
    emailLabel: {
      fontFamily: 'Calibre-Semibold,sans-serif',
      fontSize: 16,
      fontWeight: 500,
      paddingBottom: 10,
      paddingTop: 40,
    },
    audienceSizeStatus: {
      fontSize: 14,
      color: '#0000008c',
      lineHeight: '20px',
      fontWeight: 500,
    },
    infoIcon: {
      color: 'gray',
      padding: '2px',
      marginTop: '1px',
      cursor: 'pointer',
    },
    totalSize: {
      fontWeight: 500,
      lineHeight: '20px',
    },
    audienceType: {
      fontSize: 16,
      color: '#000000d9',
      lineHeight: '20px',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    platformType: {
      fontSize: 14,
      color: '#0000008c',
      fontFamily: 'Calibre-Regular, sans-serif',
    },
    popover: {
      '& > div': {
        boxShadow: 'none !important',
      },
    },
    audienceStatusCol: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      minWidth: 50,
      display: 'inline-block',
      fontSize: 16,
      fontFamily: 'Calibre-Regular, sans-serif',
    },
  })
);

interface Props {
  audience: any;
}

const InlineExportHistory = ({ audience }: Props) => {
  const exportHistory = audience?.exportHistory;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentExport, setCurrentExport] = useState(null);
  const historyData = useMemo(() => {
    return [];
    const filteredItem = exportHistory.filter(
      (x: any) => !['klover_ids', 'transunion_hems'].includes(x.exportType)
    );
    return modifyProps(filteredItem);
  }, [exportHistory]);

  const formattedRows = historyData?.map((exportHistory) => {
    const { audience_type, platform_type } = getAudienceAndPlatformTypeStatus([
      { ...exportHistory },
    ]);
    const { audinceSize, audienceExportLabel } = getAudienceEstSizeDetails(
      audience,
      exportHistory
    );
    const inHouseModelingStatus = exportHistory.inHouseModelingStatus;
    return (
      <>
        <td>
          <Styled.Type>
            <div className={classes.audienceType}>{audience_type}</div>
            <div className={classes.platformType}>{platform_type}</div>
          </Styled.Type>
        </td>

        <td>
          <Styled.AudienceStatus className={classes.audienceStatusCol}>
            <AudienceExportStatus
              value={exportHistory?.exportStatus}
              inHouseModelingStatus={inHouseModelingStatus}
            />
          </Styled.AudienceStatus>
        </td>

        <td>
          <Styled.ModeledSize>
            <div className={classes.totalSize}>{audinceSize}</div>
            <div style={{ display: 'flex' }}>
              <div className={classes.audienceSizeStatus}>
                {audienceExportLabel}
              </div>
              <div>
                <InfoIcon
                  aria-describedby="est-audience-size"
                  className={classes.infoIcon}
                  style={{ fontSize: '20px', cursor: 'pointer' }}
                  onClick={(e) => {
                    setCurrentExport(exportHistory);
                    setAnchorEl(e.currentTarget);
                  }}
                />
                {anchorEl &&
                  currentExport?.exportId === exportHistory?.exportId && (
                    <Popover
                      id="est-audience-size"
                      open={Boolean(anchorEl)}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      className={classes.popover}
                      anchorOrigin={{
                        vertical: 'center',
                        horizontal: 'right',
                      }}
                    >
                      <AudienceEstSizePopup
                        audience={audience}
                        exportHistory={exportHistory}
                      />
                    </Popover>
                  )}
              </div>
            </div>
          </Styled.ModeledSize>
        </td>
        <td>{exportHistory?.cpm || 'N/A'}</td>
        <td>{exportHistory?.percentOfMedia || 'N/A'}</td>
        <td>{exportHistory?.exportBy}</td>
        <td>{exportHistory?.segmentName}</td>
      </>
    );
  });

  return (
    <>
      <div>
        <Typography variant="h2" className={classes.exportTitle}>
          {EXPORT_HISTORY}
        </Typography>
        <TableContainer style={{ padding: '24px' }}>
          <Table>
            <TableHead>
              <TableRow>
                {Object.values(EXPORT_HISTORY_HEADERS).map((header, index) => (
                  <TableCell
                    key={index}
                    style={{ padding: '0px', width: header.width }}
                  >
                    {header.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <div style={{ marginTop: 8 }} />
            <TableBody>
              {formattedRows.length > 0 ? (
                formattedRows.map((row, index) => (
                  <TableRow key={index}>{row}</TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell
                    colSpan={7}
                    align="center"
                    style={{ border: 'none' }}
                  >
                    {NO_RECORDS_FOUND}
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};

export default InlineExportHistory;
