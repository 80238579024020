/**
 * Do not edit directly
 * Generated on Thu, 23 Jan 2025 11:06:41 GMT
 */

export const typographyHeaderFontSize = 32;
export const typographyHeaderTextDecoration = "none";
export const typographyHeaderFontFamily = "Open Sans";
export const typographyHeaderFontWeight = 700;
export const typographyHeaderFontStyle = "normal";
export const typographyHeaderFontStretch = "normal";
export const typographyHeaderLetterSpacing = 0;
export const typographyHeaderLineHeight = 48;
export const typographyHeaderParagraphIndent = 0;
export const typographyHeaderParagraphSpacing = 0;
export const typographyHeaderTextCase = "none";
export const typographySubheaderLargeFontSize = 24;
export const typographySubheaderLargeTextDecoration = "none";
export const typographySubheaderLargeFontFamily = "Open Sans";
export const typographySubheaderLargeFontWeight = 600;
export const typographySubheaderLargeFontStyle = "normal";
export const typographySubheaderLargeFontStretch = "normal";
export const typographySubheaderLargeLetterSpacing = 0;
export const typographySubheaderLargeLineHeight = 36;
export const typographySubheaderLargeParagraphIndent = 0;
export const typographySubheaderLargeParagraphSpacing = 0;
export const typographySubheaderLargeTextCase = "none";
export const typographySubheaderSmallFontSize = 18;
export const typographySubheaderSmallTextDecoration = "none";
export const typographySubheaderSmallFontFamily = "Open Sans";
export const typographySubheaderSmallFontWeight = 600;
export const typographySubheaderSmallFontStyle = "normal";
export const typographySubheaderSmallFontStretch = "normal";
export const typographySubheaderSmallLetterSpacing = 0;
export const typographySubheaderSmallLineHeight = 27;
export const typographySubheaderSmallParagraphIndent = 0;
export const typographySubheaderSmallParagraphSpacing = 0;
export const typographySubheaderSmallTextCase = "none";
export const typographyBodyFontSize = 16;
export const typographyBodyTextDecoration = "none";
export const typographyBodyFontFamily = "Open Sans";
export const typographyBodyFontWeight = 400;
export const typographyBodyFontStyle = "normal";
export const typographyBodyFontStretch = "normal";
export const typographyBodyLetterSpacing = 0;
export const typographyBodyLineHeight = 24;
export const typographyBodyParagraphIndent = 0;
export const typographyBodyParagraphSpacing = 0;
export const typographyBodyTextCase = "none";
export const typographyLabelFontSize = 14;
export const typographyLabelTextDecoration = "none";
export const typographyLabelFontFamily = "Open Sans";
export const typographyLabelFontWeight = 600;
export const typographyLabelFontStyle = "normal";
export const typographyLabelFontStretch = "normal";
export const typographyLabelLetterSpacing = 0;
export const typographyLabelLineHeight = 21;
export const typographyLabelParagraphIndent = 0;
export const typographyLabelParagraphSpacing = 0;
export const typographyLabelTextCase = "none";
export const typographyNoteFontSize = 12;
export const typographyNoteTextDecoration = "none";
export const typographyNoteFontFamily = "Open Sans";
export const typographyNoteFontWeight = 400;
export const typographyNoteFontStyle = "normal";
export const typographyNoteFontStretch = "normal";
export const typographyNoteLetterSpacing = 0;
export const typographyNoteLineHeight = 18;
export const typographyNoteParagraphIndent = 0;
export const typographyNoteParagraphSpacing = 0;
export const typographyNoteTextCase = "none";