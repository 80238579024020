import React, { useMemo } from 'react';
import {
  Box,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import { createStyles, makeStyles } from '@material-ui/core/styles';

const steps = [
  'Define your Audience',
  'Model your Audience',
  'Export your Audience',
];

interface InHouseModelStepperProps {
  dimensionCount: number;
  activeStep: number;
  setActiveStep: (step: number) => void;
  lalModelStatus: string;
  audienceName: string;
}

const useStyles = makeStyles(() =>
  createStyles({
    container: {
      padding: '2rem',
      position: 'fixed',
      bottom: 0,
      width: '-webkit-fill-available',
      zIndex: 1000,
    },
    stepLabel: {
      '& > span > .Mui-active': {
        color: '#413762',
      },
    },
  })
);

const InHouseModelStepper: React.FC<InHouseModelStepperProps> = ({
  dimensionCount,
  activeStep,
  setActiveStep,
  lalModelStatus,
  audienceName,
}) => {
  const classes = useStyles();
  const isAudienceDefined = useMemo(() => {
    return audienceName && dimensionCount > 0;
  }, [dimensionCount, audienceName]);

  const handleStepClick = (step: number) => {
    // Prevent navigation to steps beyond step 1 unless it's completed
    if (step === 1 && !isAudienceDefined) return;
    // if (step === 2 && lalModelStatus !== 'COMPLETED') return;
    setActiveStep(step);
  };

  return (
    <Box sx={{ width: '100%', py: 1 }}>
      <Paper elevation={3} className={classes.container}>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step
              key={label}
              // disabled={
              //   (index === 1 && !isAudienceDefined) ||
              //   (index === 2 && lalModelStatus !== 'COMPLETED')
              // }
            >
              <StepLabel
                className={classes.stepLabel}
                onClick={() => handleStepClick(index)} // Click handler
                style={{
                  cursor:
                    (index === 1 && !isAudienceDefined) ||
                    (index === 2 && lalModelStatus !== 'COMPLETED')
                      ? 'not-allowed'
                      : 'pointer',
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: activeStep === index ? 'bold' : 'normal',
                    color: activeStep === index ? '#413762' : 'grey.500',
                    fontFamily: 'Calibre-Regular,sans-serif',
                    fontSize: '18px',
                  }}
                >
                  {label}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Paper>
    </Box>
  );
};

export default InHouseModelStepper;
